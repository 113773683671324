import { caseManagementUrl, companyManagementUrl } from "../config";
import axios from "axios";
import {
  caseMonthlyCasesStatististics,
  recentCaseTasks,
} from "./Axios/queries";

export const fetchMonthlyStatistics = async () => {
  const postData = {
    query: caseMonthlyCasesStatististics,
  };

  try {
    const response = await axios.post(companyManagementUrl.uri, postData, {});
    return response.data;
  } catch (error) {
    return error.code;
  }
};

export const BarchartNull = [
  {
    month: "jan",
    NewCases: 0,
    NewCasesColor: "hsl(316, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(290, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(8, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(296, 70%, 50%)",
  },
  {
    month: "Feb",
    NewCases: 0,
    NewCasesColor: "hsl(29, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(330, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(263, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(177, 70%, 50%)",
  },
  {
    month: "Mar",
    NewCases: 0,
    NewCasesColor: "hsl(295, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(83, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(233, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(15, 70%, 50%)",
  },
  {
    month: "Apr",
    NewCases: 0,
    NewCasesColor: "hsl(326, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(347, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(333, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(225, 70%, 50%)",
  },
  {
    month: "May",
    NewCases: 0,
    NewCasesColor: "hsl(78, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(165, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(179, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(176, 70%, 50%)",
  },
  {
    month: "June",
    NewCases: 0,
    NewCasesColor: "hsl(211, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(256, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(186, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(204, 70%, 50%)",
  },
  {
    month: "July",
    NewCases: 0,
    NewCasesColor: "hsl(154, 70%, 50%)",
    Assigned_Cases: 0,
    Assigned_CasesColor: "hsl(251, 70%, 50%)",
    ClosedCases: 0,
    ClosedCasesColor: "hsl(303, 70%, 50%)",
    InprogressCases: 0,
    InprogressCasesColor: "hsl(8, 70%, 50%)",
  },
];

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
  };
};
export const fetchrecenttasks = async () => {
  const postData = {
    query: recentCaseTasks,
  };

  try {
    const headers = getAuthHeaders();
    const response = await axios.post(caseManagementUrl.uri, postData, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.code;
  }
};
