import React from "react";
import DynamicTabs from "../../../data/DynamicTable/DynamicTabs";

const Geographical = () => {
  const rightId = localStorage.getItem("RightIdFromSidebar");
  return (
    <div className="container-fluid">
      <DynamicTabs rightId={rightId} />
    </div>
  );
};

export default Geographical;
