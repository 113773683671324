import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AnchorTemporaryDrawer from "../../components/Drawer";
import { graphqlQuery } from "../Axios/DynamicService";
import { useNavigate } from "react-router-dom";
import AssignTaskModal, { DocumentAccessModal } from "./Modal";
import { useDispatch } from "react-redux";
import { UpdateDataToStore } from "../../store/Actions/CustomAction";
import { Card, CardBody } from "react-bootstrap";
import usePermissions from "../RBAC/PermissionsHook";
import useSubRightPermissionsHook from "../RBAC/SubRightPermissionHook";
import { documentManagementUrl } from "../../config";
import { deleteDocument, markTaskDone } from "./DynamicFunctions";
import Swal from "sweetalert2";

const base_file_path = documentManagementUrl.baseFilePath;

const DynamicTable = ({
  title,
  subtitle,
  columns,
  FormComponent,
  query,
  base_url,
  actions,
  DataFromGetBy,
  fetchData,
  loadingState,
  getRowId,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();
  const { hasSubRightPermission } = useSubRightPermissionsHook();
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const [assignDocumentAccessOpen, setAssignDocumentAccessOpen] =
    useState(false);
  const handleMarkTaskDone = async (id) => {
    try {
      const response = await markTaskDone(id);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Task marked as done succefully`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Unable to mark the task as done, try again later.",
        });
      }
      // Example: log the response
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error marking task as done:", error);
    }
  };
  const handleDeleteDocument = async (fileName, documentId) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this document? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        const response = await deleteDocument(fileName, documentId);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Document deleted successfully.`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Unable to delete the document, try again later.",
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "The document is safe.",
        });
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  async function fetchAllData() {
    try {
      if (query && base_url) {
        const data = await graphqlQuery(query, base_url);
        if (data !== null) {
          setData(data);
        }
      } else {
        setData(DataFromGetBy);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  useEffect(() => {
    fetchAllData();
  }, [query, base_url]);

  useEffect(() => {
    if (refreshTable) {
      fetchAllData();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  const filterActionsByRole = (action) => {
    if (actions[action].permission === "hasNoPermission") {
      return actions[action].roleGroupId.includes(RoleGroupId);
    } else if (actions.isSubRight) {
      return hasSubRightPermission(actions.entity, actions[action].permission);
    } else {
      return hasPermission(actions.entity, actions[action].permission);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          <Select
            value=""
            displayEmpty
            onChange={(e) => handleAction(e.target.value, row.id, row)}
          >
            <MenuItem value="" disabled>
              Actions
            </MenuItem>
            {Object.keys(actions)
              .filter(
                (action) =>
                  action !== "add" &&
                  actions[action].Show_Button &&
                  filterActionsByRole(action)
              )
              .map(
                (action) =>
                  actions[action] && (
                    <MenuItem key={actions[action].key} value={action}>
                      {actions[action].button_name}
                    </MenuItem>
                  )
              )}
          </Select>
        </Box>
      ),
    },
  ];

  const handleAdd = () => {
    setIsEditing(false);
    setEditData(null);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };
  const handleOpenTaskModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setTaskModalOpen(true);
    setEditData(itemToEdit);
  };
  const handleAssignDocumentAccessModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setAssignDocumentAccessOpen(true);
    setEditData(itemToEdit);
  };
  const handleEdit = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setEditData(itemToEdit);
    setIsEditing(true);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };
  const handleAction = async (action, id, row) => {
    switch (action) {
      case "edit":
        handleEdit(id);
        break;
      case "add":
        handleAdd();
        break;
      case "documentAccess":
        handleAssignDocumentAccessModal(id);
        break;
      case "ViewCase":
        localStorage.setItem("CaseId", JSON.stringify(id));
        navigate("/case-details");
        break;
      case "AssignTask":
        handleOpenTaskModal(id);
        break;
      case "markTask":
        await handleMarkTaskDone(id);
        break;
      case "deleteDocument":
        handleDeleteDocument(row.filePath, id);
        break;
      case "viewDocument":
        const filePath = `${base_file_path}&documentId=${row?.id}&file=${row?.filePath}`;
        const actionKey = "UPDATE_ACTIVE_DOCUMENT";
        dispatch(UpdateDataToStore(actionKey, filePath));
        break;
      default:
        break;
    }
  };
  return (
    <Box m="20">
      <Box
        height="75vh"
        m="0px 0 0 0"
        // width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header title={title} subtitle={subtitle} />
          {actions.add &&
            actions.add.Show_Button &&
            (actions.isSubRight
              ? hasSubRightPermission(actions.entity, actions.add.permission)
              : hasPermission(actions.entity, actions.add.permission)) && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="contained"
                  size="sm"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={() => handleAction("add")}
                >
                  <Typography sx={{ color: colors.primary[400], mx: 1 }}>
                    {actions.add.button_name}
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
        <DataGrid
          checkboxSelection
          rows={data || DataFromGetBy || []}
          columns={columnsWithActions}
          components={{ Toolbar: GridToolbar }}
          sx={{ minWidth: isMobile ? "auto" : "auto", width: "100%" }}
          getRowId={getRowId}
        />
        {taskModalOpen && (
          <AssignTaskModal
            data={editData}
            open={taskModalOpen}
            onClose={() => setTaskModalOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}
        {assignDocumentAccessOpen && (
          <DocumentAccessModal
            data={editData}
            open={assignDocumentAccessOpen}
            onClose={() => setAssignDocumentAccessOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}

        <AnchorTemporaryDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          FormComponent={() => (
            <FormComponent
              isEditing={isEditing}
              data={editData}
              onAction={() => setRefreshTable(true)}
              onClose={() => setDrawerOpen(false)}
              refetchData={() => {
                fetchData();
                setRefreshTable(true);
              }}
            />
          )}
        />
        {isMobile && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullScreen={isMobile}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setDialogOpen(false)}
                aria-label="close"
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormComponent
                onClick={() => setDialogOpen(false)}
                isEditing={isEditing}
                courtData={editData}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Box>
  );
};

export default DynamicTable;
