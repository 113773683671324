import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

import {
  fetchDataEngine,
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import { companyManagementUrl } from "../../../config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../../../components/Header";
import { allCompanyEmailSettings } from "../../../data/Axios/queries";

// Define validation schema using Yup
const validationSchema = yup.object().shape({
  companyEmail: yup.string(),
  contactEmail: yup.string(),
  contactEmailPassword: yup.string(),
  emailDisplayName: yup.string(),
  emailClient: yup.string(),
  emailClientPortNumber: yup.number(),
});

const EmailSettingsForm = (props) => {
  const [data, setData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const companyId = parseInt(decodedToken.CompanyId);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const companyDataResponse = await graphqlQuery(allCompanyEmailSettings, companyManagementUrl.uri);
        if (companyDataResponse) {
          setData(companyDataResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      const response = await generateAndExecuteMutation(
        "updateCompanyEmailSettings",
        values,
        "settings",
        companyManagementUrl.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.OnAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Company Email ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    companyEmail: data.companyEmail || "",
    contactEmail: data.contactEmail || "",
    contactEmailPassword: data.contactEmailPassword || "",
    emailDisplayName: data.emailDisplayName || "",
    emailClient: data.emailClient || "",
    emailClientPortNumber: data.emailClientPortNumber || "",
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box m={2}>
      <Box my={2}>
        <Header
          title={"Manage Company Email"}
          subtitle={"View or update company email"}
        />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="companyEmail"
                  label="Company Email"
                  variant="outlined"
                  fullWidth
                  value={values.companyEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyEmail && Boolean(errors.companyEmail)}
                  helperText={touched.companyEmail && errors.companyEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="contactEmail"
                  label="Contact Email"
                  variant="outlined"
                  fullWidth
                  value={values.contactEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.contactEmail && Boolean(errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="contactEmailPassword"
                  label="Contact Email Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  value={values.contactEmailPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.contactEmailPassword &&
                    Boolean(errors.contactEmailPassword)
                  }
                  helperText={
                    touched.contactEmailPassword && errors.contactEmailPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="emailDisplayName"
                  label="Email Display Name"
                  variant="outlined"
                  fullWidth
                  value={values.emailDisplayName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emailDisplayName && Boolean(errors.emailDisplayName)
                  }
                  helperText={
                    touched.emailDisplayName && errors.emailDisplayName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="emailClient"
                  label="Email Client"
                  variant="outlined"
                  fullWidth
                  value={values.emailClient}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailClient && Boolean(errors.emailClient)}
                  helperText={touched.emailClient && errors.emailClient}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="emailClientPortNumber"
                  label="Email Client Port Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={values.emailClientPortNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emailClientPortNumber &&
                    Boolean(errors.emailClientPortNumber)
                  }
                  helperText={
                    touched.emailClientPortNumber &&
                    errors.emailClientPortNumber
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EmailSettingsForm;
