import React from "react";
import { Container, Grid, Typography, Link, useTheme, Box, IconButton } from "@mui/material";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import { resourcesLinks, platformLinks, communityLinks } from "../../../components/LandingPage";
import { tokens } from "../../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <footer className="mt-20">
      <Box sx={{ width: '100vw', backgroundColor: colors.blueAccent[900] }}>
        <Container sx={{ p: 1, mt: 10 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Resources
              </Typography>
              <ul>
                {resourcesLinks.map((link, index) => (
                  <li key={index}>
                    <Link href={link.href} color="textSecondary" underline="hover">
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Platform
              </Typography>
              <ul>
                {platformLinks.map((link, index) => (
                  <li key={index}>
                    <Link href={link.href} color="textSecondary" underline="hover">
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Community
              </Typography>
              <ul>
                {communityLinks.map((link, index) => (
                  <li key={index}>
                    <Link href={link.href} color="textSecondary" underline="hover">
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 4 }}>
            <Grid item>
              <IconButton href="#" aria-label="Facebook" color="inherit">
                <Facebook />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton href="#" aria-label="Twitter" color="inherit">
                <Twitter />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton href="#" aria-label="Instagram" color="inherit">
                <Instagram />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
            {"Copyright © "}
            <Link color="inherit" href="mcb.co.ke">
              Sheria Pro
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
