import { Box, CircularProgress, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import { Check, Save } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { generateAndExecuteMutation } from "../Axios/DynamicService";

const DynamicEditableActions = ({
  params,
  rowId,
  setRowId,
  actions,
  base_url,
  isNew,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
      setLoading(true);
      const isAdding = !params.row.id;
      const mutationName = isAdding
        ? actions.add.inputObjectName
        : actions.edit.inputObjectName;
      const inputObject = isAdding
        ? actions.add.InputObject
          : actions.edit.InputObject;
      const values = {
          ...params.row,
          id:parseInt(params.row.id)
      };
    const response = await generateAndExecuteMutation(
      mutationName,
      values,
      inputObject,
      base_url
    );
    if (response[0]) {
      console.log("user created succes");
      setSuccess(true);
      setRowId(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default DynamicEditableActions;
