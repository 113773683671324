import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import {
  ExecuteBulkMutationForRights,
  GetRoleRightsByRoleId,
  generateAndExecuteBulkMutation,
} from "../../../data/Axios/DynamicService";
import Swal from "sweetalert2";
import { roleManagemenUrl } from "../../../config";

const AssignRightsForm = ({ roleRightsData, roleId, onClose }) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedModules, setSelectedModules] = useState({});
  const [selectedRights, setSelectedRights] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [selectedSubrights, setSelectedSubrights] = useState({});
  const [selectedSubrightPermissions, setSelectedSubrightPermissions] =
    useState({});
  const [loading, setLoading] = useState(true);
  const [rolesbyroleiddata, setrolesbyroleiddata] = useState([]);

  console.log("Role Right By Role Data", rolesbyroleiddata);

  const fetchData = async () => {
    setLoading(true);
    try {
      const roleRightsDataByRole = await GetRoleRightsByRoleId(roleId);
      if (roleRightsDataByRole && roleRightsDataByRole.length > 0) {
        const cleanedRoleRightByRoleData = JSON.parse(
          roleRightsDataByRole.replace(/\\/g, "")
        );
        setrolesbyroleiddata(cleanedRoleRightByRoleData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [roleId, onClose]);

  useEffect(() => {
    if (rolesbyroleiddata) {
      initializeSelections();
    }
  }, [rolesbyroleiddata]);

  const initializeSelections = () => {
    const initialSelectedModules = {};
    const initialSelectedRights = {};
    const initialSelectedPermissions = {};
    const initialSelectedSubrights = {};
    const initialSelectedSubrightPermissions = {};

    roleRightsData[0].forEach((module) => {
      initialSelectedModules[module.id] = false;
      initialSelectedRights[module.id] = {};
      initialSelectedPermissions[module.id] = {};
      initialSelectedSubrights[module.id] = {};
      initialSelectedSubrightPermissions[module.id] = {};

      if (!rolesbyroleiddata) {
        return;
      }
      const moduleExists = rolesbyroleiddata?.some(
        (item) => item.id === module.id
      );

      if (moduleExists) {
        initialSelectedModules[module.id] = true;

        module.children?.forEach((right) => {
          const moduleData = rolesbyroleiddata.find(
            (item) => item.id === module.id
          );
          if (moduleData && moduleData.children) {
            const rightExists = moduleData.children.some(
              (item) => item.id === right.id
            );
            if (rightExists) {
              initialSelectedRights[module.id][right.id] = true;

              initialSelectedPermissions[module.id][right.id] = {};
              initialSelectedSubrights[module.id][right.id] = {};
              initialSelectedSubrightPermissions[module.id][right.id] = {};

              const rightData = moduleData.children?.find(
                (item) => item.id === right.id
              );
              if (rightData) {
                Object.entries(rightData.permissions)?.forEach(
                  ([permission, value]) => {
                    initialSelectedPermissions[module.id][right.id][
                      permission
                    ] = value === 1;
                  }
                );

                right.subrights?.forEach((subright) => {
                  console.log("Subright:", subright);
                  const subrightExists = rightData.subrights?.some(
                    (item) => item.id === subright.id
                  );
                  if (subrightExists) {
                    initialSelectedSubrights[module.id][right.id][
                      subright.id
                    ] = true;

                    initialSelectedSubrightPermissions[module.id][right.id][
                      subright.id
                    ] = {};
                    const subrightData = rightData.subrights?.find(
                      (item) => item.id === subright.id
                    );
                    if (subrightData) {
                      Object.entries(subrightData.permissions)?.forEach(
                        ([permission, value]) => {
                          initialSelectedSubrightPermissions[module.id][
                            right.id
                          ][subright.id][permission] = value === 1;
                        }
                      );
                    }
                  } else {
                    initialSelectedSubrights[module.id][right.id][
                      subright.id
                    ] = false;
                    initialSelectedSubrightPermissions[module.id][right.id][
                      subright.id
                    ] = {};
                  }
                });
              }
            }
          }
        });
      }
    });

    setSelectedModules(initialSelectedModules);
    setSelectedRights(initialSelectedRights);
    setSelectedPermissions(initialSelectedPermissions);
    setSelectedSubrights(initialSelectedSubrights);
    setSelectedSubrightPermissions(initialSelectedSubrightPermissions);
  };

 
  const handleCheckboxChange = (
    moduleId,
    rightId,
    permission,
    isChecked,
    subrightId
  ) => {
    if (subrightId) {
      if (permission) {
        setSelectedSubrightPermissions((prev) => ({
          ...prev,
          [moduleId]: {
            ...prev[moduleId],
            [rightId]: {
              ...prev[moduleId][rightId],
              [subrightId]: {
                ...prev[moduleId][rightId][subrightId],
                [permission]: isChecked,
              },
            },
          },
        }));
      } else {
        const updatedPermissions = Object.keys(
          selectedSubrightPermissions[moduleId]?.[rightId]?.[subrightId] || {}
        ).reduce((acc, perm) => {
          acc[perm] = isChecked;
          return acc;
        }, {});
        setSelectedSubrights((prev) => ({
          ...prev,
          [moduleId]: {
            ...prev[moduleId],
            [rightId]: {
              ...prev[moduleId][rightId],
              [subrightId]: isChecked,
            },
          },
        }));
        setSelectedSubrightPermissions((prev) => ({
          ...prev,
          [moduleId]: {
            ...prev[moduleId],
            [rightId]: {
              ...prev[moduleId][rightId],
              [subrightId]: updatedPermissions,
            },
          },
        }));
      }
    } else if (permission) {
      setSelectedPermissions((prev) => ({
        ...prev,
        [moduleId]: {
          ...prev[moduleId],
          [rightId]: {
            ...prev[moduleId][rightId],
            [permission]: isChecked,
          },
        },
      }));
    } else if (rightId) {
      const updatedPermissions = Object.keys(
        selectedPermissions[moduleId]?.[rightId] || {}
      ).reduce((acc, perm) => {
        acc[perm] = isChecked;
        return acc;
      }, {});
      setSelectedRights((prev) => ({
        ...prev,
        [moduleId]: {
          ...prev[moduleId],
          [rightId]: isChecked,
        },
      }));
      setSelectedPermissions((prev) => ({
        ...prev,
        [moduleId]: {
          [rightId]: updatedPermissions,
        },
      }));
    } else {
      const updatedRights = Object.keys(selectedRights[moduleId] || {}).reduce(
        (acc, right) => {
          acc[right] = isChecked;
          return acc;
        },
        {}
      );
      const updatedPermissions = Object.keys(
        selectedPermissions[moduleId] || {}
      ).reduce((acc, right) => {
        acc[right] = Object.keys(
          selectedPermissions[moduleId][right] || {}
        ).reduce((permAcc, perm) => {
          permAcc[perm] = isChecked;
          return permAcc;
        }, {});
        return acc;
      }, {});
      const updatedSubrights = Object.keys(
        selectedSubrights[moduleId] || {}
      ).reduce((acc, right) => {
        acc[right] = Object.keys(
          selectedSubrights[moduleId][right] || {}
        ).reduce((subAcc, subright) => {
          subAcc[subright] = isChecked;
          return subAcc;
        }, {});
        return acc;
      }, {});
      const updatedSubrightPermissions = Object.keys(
        selectedSubrightPermissions[moduleId] || {}
      ).reduce((acc, right) => {
        acc[right] = Object.keys(
          selectedSubrightPermissions[moduleId][right] || {}
        ).reduce((subAcc, subright) => {
          subAcc[subright] = Object.keys(
            selectedSubrightPermissions[moduleId][right][subright] || {}
          ).reduce((permAcc, perm) => {
            permAcc[perm] = isChecked;
            return permAcc;
          }, {});
          return subAcc;
        }, {});
        return acc;
      }, {});
      setSelectedModules((prev) => ({
        ...prev,
        [moduleId]: isChecked,
      }));
      setSelectedRights((prev) => ({
        ...prev,
        [moduleId]: updatedRights,
      }));
      setSelectedPermissions((prev) => ({
        ...prev,
        [moduleId]: updatedPermissions,
      }));
      setSelectedSubrights((prev) => ({
        ...prev,
        [moduleId]: updatedSubrights,
      }));
      setSelectedSubrightPermissions((prev) => ({
        ...prev,
        [moduleId]: updatedSubrightPermissions,
      }));
    }
  };
  const flattenPermissions = (permissions) => {
    return Object.keys(permissions).flatMap((moduleId) =>
      Object.keys(permissions[moduleId] || {}).flatMap((rightId) => {
        const currentPermissions = permissions[moduleId][rightId];
        return {
          moduleId: parseInt(moduleId),
          rightId: parseInt(rightId),
          roleId, // Ensure roleId is defined in the scope
          createdBy: userId, // Ensure userId is defined in the scope
          createPermission: currentPermissions.includes("CreatePermission")
            ? 1
            : 0,
          editPermission: currentPermissions.includes("EditPermission") ? 1 : 0,
          activatePermission: currentPermissions.includes("ActivatePermission")
            ? 1
            : 0,
          exportPermission: currentPermissions.includes("ExportPermission")
            ? 1
            : 0,
          statusFlag: null,
          createdDate: new Date().toISOString(),
          updatedBy: userId,
          updatedDate: new Date().toISOString(),
          companyId: null,
          company: null,
          subRights: [],
        };
      })
    );
  };
  const flattenSubrightPermissions = (permissions) => {
    return Object.keys(permissions).flatMap((moduleId) =>
      Object.keys(permissions[moduleId] || {}).flatMap((rightId) =>
        Object.keys(permissions[moduleId][rightId] || {}).flatMap(
          (subrightId) => {
            const currentPermissions =
              permissions[moduleId][rightId][subrightId];
            return {
              roleId: parseInt(roleId),
              // moduleId: parseInt(moduleId),
              rightId: parseInt(rightId),
              subRightId: parseInt(subrightId),
              subRight: currentPermissions.subRightName || "", // Assuming subRightName is a part of subrightPermissions
              createPermission: currentPermissions.includes("CreatePermission")
                ? 1
                : 0,
              editPermission: currentPermissions.includes("EditPermission")
                ? 1
                : 0,
              activatePermission: currentPermissions.includes(
                "ActivatePermission"
              )
                ? 1
                : 0,
              exportPermission: currentPermissions.includes("ExportPermission")
                ? 1
                : 0,
              statusFlag: null,
              createdBy: userId,
              createdDate: new Date().toISOString(),
              updatedBy: userId,
              updatedDate: new Date().toISOString(),
              companyId: null,
              company: null,
            };
          }
        )
      )
    );
  };
  const combinePermissions = (mainPermissions, subrightPermissions) => {
    const permissionMap = {};

    mainPermissions.forEach((permission) => {
      const key = `${permission.moduleId}-${permission.rightId}`;
      permissionMap[key] = permission;
    });

    subrightPermissions.forEach((subright) => {
      const key = `${subright.rightId}`;
      if (permissionMap[key]) {
        permissionMap[key].subRights.push(subright);
      } else {
        permissionMap[key] = {
          moduleId: parseInt(subright.moduleId),
          rightId: parseInt(subright.rightId),
          roleId: parseInt(subright.roleId),

          createPermission: 0,
          editPermission: 0,
          activatePermission: 0,
          exportPermission: 0,
          isActive: true,
          statusFlag: null,
          createdDate: new Date().toISOString(),
          updatedBy: userId,
          updatedDate: new Date().toISOString(),
          companyId: null,
          company: null,
          subRights: [subright],
        };
      }
    });

    return Object.values(permissionMap);
  };
 const consolidatedPermissions = {};
 Object.keys(selectedModules).forEach((moduleId) => {
   if (selectedModules[moduleId]) {
     consolidatedPermissions[moduleId] = {};
     Object.keys(selectedRights[moduleId] || {}).forEach((rightId) => {
       if (selectedRights[moduleId][rightId]) {
         consolidatedPermissions[moduleId][rightId] = Object.keys(
           selectedPermissions[moduleId][rightId] || {}
         ).filter(
           (permission) => selectedPermissions[moduleId][rightId][permission]
         );
       }
     });
   }
 });
const consolidatedSubrightPermissions = {};
Object.keys(selectedModules).forEach((moduleId) => {
  if (selectedModules[moduleId]) {
    consolidatedSubrightPermissions[moduleId] = {};
    Object.keys(selectedRights[moduleId] || {}).forEach((rightId) => {
      if (selectedRights[moduleId][rightId]) {
        consolidatedSubrightPermissions[moduleId][rightId] = {};
        Object.keys(selectedSubrights[moduleId][rightId] || {}).forEach(
          (subrightId) => {
            if (selectedSubrights[moduleId][rightId][subrightId]) {
              consolidatedSubrightPermissions[moduleId][rightId][subrightId] =
                Object.keys(
                  selectedSubrightPermissions[moduleId][rightId][subrightId] ||
                    {}
                ).filter(
                  (permission) =>
                    selectedSubrightPermissions[moduleId][rightId][subrightId][
                      permission
                    ]
                );
            }
          }
        );
      }
    });
  }
});
  const handleSubmit = async () => {
    const mainPermissions = flattenPermissions(consolidatedPermissions);
    const subrightPermissions = flattenSubrightPermissions(
      consolidatedSubrightPermissions
    );

    const combinedPermissions = combinePermissions(
      mainPermissions,
      subrightPermissions
    );

    try {
      const response = await ExecuteBulkMutationForRights(
        "createRoleRightsAndSubRightsBulk",
        "newRoleRights",
        combinedPermissions,
        roleManagemenUrl.uri
      );
      if (Object.values(response)[0] != null) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Permissions updated successfully!",
        });
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating permissions.",
      });
      console.error("Error updating permissions:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Header
        title="Assign Rights"
        subtitle="Assign rights to the selected role"
      />
      <Box>
        {roleRightsData[0]?.map((module) => (
          <Box
            key={module.id}
            m="20px 0 0 0"
            sx={{
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedModules[module.id]}
                  onChange={(e) =>
                    handleCheckboxChange(
                      module.id,
                      null,
                      null,
                      e.target.checked
                    )
                  }
                />
              }
              label={
                <Typography color={colors.greenAccent[400]}>
                  {module.title}
                </Typography>
              }
            />
            {module.children?.map((right) => (
              <Box key={right.id} ml={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRights[module.id]?.[right.id] || false}
                      onChange={(e) =>
                        handleCheckboxChange(
                          module.id,
                          right.id,
                          null,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={
                    <Typography color={colors.blueAccent[400]}>
                      {right.title}
                    </Typography>
                  }
                />
                <Box ml={4}>
                  {[
                    "CreatePermission",
                    "EditPermission",
                    "ActivatePermission",
                    "ExportPermission",
                  ]?.map((permission) => (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Checkbox
                          checked={
                            selectedPermissions[module.id]?.[right.id]?.[
                              permission
                            ] || false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              module.id,
                              right.id,
                              permission,
                              e.target.checked
                            )
                          }
                        />
                      }
                      label={permission}
                    />
                  ))}
                  {right.subrights?.map((subright) => (
                    <Box key={subright.id} ml={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedSubrights[module.id]?.[right.id]?.[
                                subright.id
                              ] || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                module.id,
                                right.id,
                                null,
                                e.target.checked,
                                subright.id
                              )
                            }
                          />
                        }
                        label={
                          <Typography color={colors.redAccent[400]}>
                            {subright.title}
                          </Typography>
                        }
                      />
                      <Box ml={4}>
                        {[
                          "CreatePermission",
                          "EditPermission",
                          "ActivatePermission",
                          "ExportPermission",
                        ]?.map((permission) => (
                          <FormControlLabel
                            key={permission}
                            control={
                              <Checkbox
                                checked={
                                  selectedSubrightPermissions[module.id]?.[
                                    right.id
                                  ]?.[subright.id]?.[permission] || false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    module.id,
                                    right.id,
                                    permission,
                                    e.target.checked,
                                    subright.id
                                  )
                                }
                              />
                            }
                            label={permission}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{ ml: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AssignRightsForm;
