export const allDepartments =`
{
    allDepartments {
      id
      department
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
    }
  }
`;

export const allDesignation = `
    {
    allDesignations {
        id
        designation
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}
`;

export const allDocumentTypes = `
    {
    allDocumentTypes {
      id
      documentType
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }
`;

export const allEvents = `
    {
    allEventTypes {
      id
      eventType
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
    }
  }
`;

export const allFactCategories = `
    {
    allFactCategories {
      categoryId
      categoryName
      companyId
      company
    }
  }
`;

export const allPartyTypes =
`
    {
    allPartyTypes {
      id
      partyType
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
    }
  }
`;