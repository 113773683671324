import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl,setupManagementUrl } from "../../../config";
import {AddOnColumns} from "../../../data/DynamicTable/CompanyManagementColumns";
import CompanyForm from "./form";
import {allAddOns} from "../../../data/Axios/queries";
import AddOnForm from "./form";

// const base_url = companyManagementUrl.uri;
const setup_url = setupManagementUrl.uri;
const actions = {
  entity: "Companies",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function AddOnsTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Add-ons"
          subtitle="Create, view or edit  Add-ons"
          columns={AddOnColumns}
          FormComponent={AddOnForm}
          query={allAddOns}
          base_url={setup_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default AddOnsTable;
