export const getrolesquery = `
   {
    allRoles {
      id
      role
      roleDescription
      roleGroupId
      roleGroup
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
  }
  `;
export const AllModules = `
    {
    allModules {
      id
      module
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
  }
  `;
export const allCasesDynamic = `{
  allCasesDynamicView {
    assignedTo
    caseNumber
    caseStatus
    categoryName
    clientType
    companyName
    createdBy
    createdDate
    dateClosed
    dateOpened
    description
    id
    isActive
    isInvoiced
    priority
    statusFlag
    subcategoryName
    summary
    title
    updatedBy
    updatedDate
  }
}`;

export const caseCategory = `{
  allCaseCategories {
    id
    categoryName
    description
    companyId
    company
  }
}`;
export const caseSubCategory = `{
{
  allCaseSubcategories {
    id
    categoryId
    subcategoryName
    description
    companyId
    company
  }
}`;

export const caseEvents = `
{
  allCaseEvents {
    id
    caseId
    eventTypeId
    eventType
    eventDate
    outcome
    hearingDate
    description
    createdBy
    createdDate
    companyId
    company
  }
}
`;

export const caseTasks = `{
  allCaseTaskView {
    caseNumber
    companyName
    createdBy
    createdDate
    description
    dueDate
    id
    isActive
    name
    statusFlag
    updatedBy
    updatedDate
  }
}`;
export const allFacts = `{
  allFactView {
    caseNumber
    categoryName
    companyName
    createdBy
    createdDate
    factDate
    factDescription
    id
    updatedBy
    updatedDate
  }
}`;

export const allCasesEvents = `{
  allCaseEventsView {
    caseNumber
    companyName
    createdBy
    createdDate
    description
    eventDate
    eventType
    hearingDate
    id
    outcome
  }
}`;

export const allEventTypes = ` {
  allEventTypes {
      id
      eventType
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
  }
}
`;

export const allIndividualClients = `
{
  allIndividualClients {
    id
    firstName
    lastName
    dateOfBirth
    genderId
    gender
    nationality
    identificationType
    identificationNumber
    contactNumber
    email
    address
    occupation
    employer
    emergencyContactName
    emergencyContactNumber
    relationshipWithEmergencyContact
    dateAdded
    notes
    isActive
    statusFlag
    createdBy
    createdDate
    updatedBy
    updatedDate
    companyId
    company
    genderId
  }
}
`;

export const allCorporateClients = `
{
  allCorporateClients {
    id
    contactNumber
    email
    address
    dateAdded
    notes
    companyName
    registrationNumber
    industrySector
    contactPersonName
    contactPersonPosition
    contactPersonEmail
    contactPersonPhone
    companyAddress
    billingAddress
    incorporationDate
    legalStructure
    countryOfIncorporation
    taxIdentificationNumber
    authorizedSignatoryName
    authorizedSignatoryPosition
    authorizedSignatoryEmail
    authorizedSignatoryPhone
    isActive
    statusFlag
    createdBy
    createdDate
    updatedBy
    updatedDate
    companyId
    company
  }
}
`;
export const allUsersQuery = `{
    allUsers {
      id
      firstName
      username
      lastName
      idno
      phone
      gender
      email
      isActive
      roleName
    }
  }`;

export const allCourts = ` {
  allCourts {
    id
    court
    courtTypeId
    courtType
    countryId
    country
    countyId
    county
    subCountyId
    subCounty
    latitude
    longitude
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    company
  }
  }`;

export const allCompanies = `{
  allCompanies {
    id
    companyName
    subscriptionId
    createdBy
    createdDate
    updatedBy
    updatedDate
  }
}`;

export const allCounties = `{
    allCounties {
      id
      county
      countyCode
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }`;

export const allAccountTypes = ` 
  {
  allAccountTypes {
      id
      accountType
      company
      companyId
    }
  } `;
export const allAccountCategories = `{
  allAccountCategories {
      id
      status
      categoryName
      currency
      rate
      parentAccount
      oldParent
      companyId
      company
    }
  }
  `;
export const allAccountSubCategories = ` {
  allAccountSubCategories {
      accountCategory
      accountCategoryId
      accountName
      accountNumber
      accountType
      company
      companyId
      currency
      id
      parentAccount
      rate
      status
    }
  }
  `;
export const allAccounts = ` {
  allAccounts {
      id
      status
      accountName
      accountNumber
      accountType
      companyId
      company
      currencyId
      currency
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
  `;
export const allPaymentTypes = `{
  allPaymentTypes {
      id
      paymentType
      company
      companyId

    }
  }
  `;
export const allBanks = `{
  allBanks {
      id
      bankName
      bankSwiftCode
      finCode
      mobile
      createdDate
      createdBy
      email
      companyId
      company
    }
  }

  `;
export const allBankBranches = `{
  allBankBranches {
      id
      bankId
      branchCode
      branchName
      branchSwiftCode
      createdDate
      createdBy
      companyId
      company
    }
  }

  `;
export const allBankBranchView = `{
    allBankBranchesView {
      bankName
      branchCode
      branchName
      branchSwiftCode
      id
    }
  }`;
export const allBankAccounts = `{
  allBankBranches {
      id
      bankId
      branchCode
      branchName
      branchSwiftCode
      createdDate
      createdBy
      companyId
      company
    }
  }
  `;
export const allBanksBankBranchesAccountView = `{
    allBanksBranchAccountView {
      accountDescription
      accountName
      accountNumber
      bankName
      branchName
      id
      status
    }
  }`;
export const allFiscalYears = `{
  allFiscalYears {
      id
      fiscalYearName
      startDate
      endDate
      status
      createdDate
      createdBy
      companyId
      company
    }
  }

  `;
export const allTaxAuthorities = `{
  allTaxAuthoritiesview {
    country
    id
    jurisdiction
    taxAuthorityName
  }
  }
  `;
export const allTaxRates = `{
  allTaxRatesView {
    country
    id
    name
    rate
    taxAuthorityName
  }
  }
  `;

export const allJudgesQuery = `
  {
  allJudges {
      company
      companyId
      judgeId
      name
    }
  }
  `;

export const allCountries = `{
    allCountries {
      id
      country
      countryInitials
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }`;
export const allRegions = `{
  allRegions {
    id
    regionName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    company
  }
}
`;
export const allBranches = `
{
  allBranches {
    id
    branchName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    company
    regionId
    region
  }
}
`;

export const allDepartments = `{
  allDepartments {
    id
    departmentName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    company
  }
}`;
export const allAddOns = `
    {
  allAddOns {
    id
    addOnName
    description
    price
    isActive
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}
`;
export const allCompanyAddOns = `
  {
  allCompanyAddOns {
    id
    companyId
    addOnId
    addOn
    purchasedDate
    
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}

`;
export const allSubscriptionPlans = `
 {
  allSubscriptionPlans {
    id
    plan
    description
    price
    durationMonths
    storageLimit
    storageUnitId
    isActive
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}

`;
export const allStorageUnits = `
{
  allStorageUnits {
    id
    unit
    description
  }
}

`;
export const allSmsSettings = ` {
    smsSettings {
        companyId
        company
        active
        promotionalAmount
        transactionalAmount
        smsLimit
    }
  }
`;
export const notificationsAndCount = `{
    notificationsByUser {
        count
        notifications {
            notificationId
            userId
            notificationType
            body
            templateId
            isRead
            createdDate
            companyId
            company
        }
    }
}
`;

export const allCaseTask = `{
    allCaseTasks {
      id
      caseId
      asigneeId
      description
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
  }`;
export const allCompanyEmailSettings = `{
  companyEmailSettingsById {
    companyId
    companyName
    companyEmail
    contactEmail
    contactEmailPassword
    emailClient
    emailClientPortNumber
    emailDisplayName
  }
}`;

export const allCourtAssignments = `{
    allCourtAssignments {
        id
        caseId
        courtId
        assignedDate
        createdBy
        createdDate
        companyId
        company
    }
}`;

export const allCaseEvents = `{
    allCaseEvents {
        id
        caseId
        eventTypeId
        eventType
        eventDate
        outcome
        hearingDate
        description
        createdBy
        createdDate
        companyId
        company
    }
}
`;

export const allJudgeAssignments = ` {
    allJudgeAssignments {
        id
        caseId
        judgeId
        assignedDate
        createdBy
        createdDate
        companyId
        company
    }
}
`;

export const allTeamAssignment = `{
    allTeamAssignmentView {
      assignedDate
      caseNumber
      companyName
      createdBy
      createdDate
      id
      isActive
      statusFlag
      updatedBy
      updatedDate
      userName
    }
  }`;

export const mainDashboardQuery = `
  {
    companySummary {
        numberOfCases
        numberOfCourtCases
        numberOfCorporateClients
        numberOfIndividualClients
        numberOfCaseEvents
        numberOfCaseTasks
        numberOfNewClients
        corporateClientPercentage
        individualClientPercentage
        courtCasePercentage
        casesClosed
        matterCompletion
        taskCompletion
        }

}

`;
export const allCasesView = `
{
    allCasesView {
      id
        clientType
        caseNumber
        title
        description
        categoryName
        subcategoryName
        caseStatus
        dateOpened
        statusFlag
       name
    }
}`;
export const caseTaskForDashboard = `{
    recentCasesDynamic {
        id
        caseId
        asigneeId
        caseNumber
        title
        firstName
        lastName
        description
        caseDescription
        isActive
        done
        statusFlag
        dueDate
        createdDate
        regionId
        branchId
        companyId
        company
    }
}`;

export const caseMonthlyCasesStatististics = `
{
  monthlyStatisticsByYear(year: 2024) {
    assignedCases
    assignedCasesColor
    closedCases
    closedCasesColor
    inprogressCases
    inprogressCasesColor
    month
    newCases
    newCasesColor
  }
}
`;

export const allAdhocSmS = `
{
    smSesByCompany {
        messageId
        phoneNumber
        body
        sentDateTime
        status
     
     
    }
}
`;
export const allAdhocEmails = `{
    emailsByCompany {
        messageId
        recipientEmail
        subject
        body
        sentDateTime
        status
     
    }
}
`;

export const allGenders = `
{
    allGenders {
        id
        gender
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}`;
export const allSubRights = `
 {
    allSubRights {
        id
        title
        icon
        action
        rightId
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        company
    }
}`;
export const allRights = `{
    allRights {
     id
        title
        icon
        action
        moduleId
        module
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        company
        
    }
}`;
export const allRoleGroups = `{
    allRoleGroups {
        id
        roleGroup
     
    }
}
`;
 
export const recentCaseTasks=`
{
    recentCaseTasks(taskModel: { companyId: null }) {
        id
        description
        caseId
        caseTitle
        assigneeFirstName
        assigneeLastName
        isActive
        statusFlag
        dueDate
        createdDate
        asigneeId
        createdBy
        companyId
        done
    }
}`