import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { allCorporateClients } from "../../../data/Axios/queries";
import { clientManagementUrl } from "../../../config";
import CorporateClientsForm from "./form";
import { CorporateClientsColumns } from "../../../data/DynamicTable/Collumns";
const base_url = clientManagementUrl.uri;
const actions = {
  entity: "Corporate Clients",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
};
function CorporateClients() {
  return (
    <Box m="2px">

      <DynamicTable
        title="Corporate Clients"
        subtitle="View all Corporate Clients, create edit or view details"
        columns={CorporateClientsColumns}
        FormComponent={CorporateClientsForm}
        query ={allCorporateClients}
        base_url={base_url}
        actions={actions}
        entity="Corporate Clients"
      />
    </Box>
  );
}

export default CorporateClients;
