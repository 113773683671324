import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

import Header from "../../../../components/Header";
import { generateAndExecuteBulkMutation, graphqlQuery } from "../../../../data/Axios/DynamicService";
import { caseManagementUrl, setupManagementUrl } from "../../../../config";
import { allJudgesQuery } from "../../../../data/Axios/queries";

const JudgeAssignmentForm = (props) => {
  const base_url = caseManagementUrl.uri;
  const judges_url = setupManagementUrl.uri;
  const [judgesData, setJudgesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchAllData() {
    try {
      const data = await graphqlQuery(allJudgesQuery, judges_url);
      if (data !== null) {
        setJudgesData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  const initialValues = {
    judgeId: props.data && props.data.judgeId ? props.data.judgeId : [],
    name: props.data ? props.data.name : "",
  };

  const validationSchema = yup.object().shape({
    judgeId: yup.array().min(1, "At least one judge is required"),
  });

  const caseIdFromSession = JSON.parse(localStorage.getItem("CaseId"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userIdFromSession = parseInt(decodedToken.Id);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const bulkData = values.judgeId.map((judgeId) => ({
        caseId: caseIdFromSession,
        judgeId: judgeId,
        createdBy: userIdFromSession,
        companyId: null,
        company: null,
      }));

      const response = await generateAndExecuteBulkMutation(
        props.isEditing ? "updateJudgeAssignment" : "bulkCreateJudgeAssignments",
        props.isEditing ? "newJudgeAssignment" : "newJudgeAssignments",
        bulkData,
        base_url
      );

      if (response.createEditJudgeAssignment) {
        props.onClose();
        if (props.refetchData()) {
          props.refetchData();
        }
        resetForm();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `JudgeAssignment ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }

      setSubmitting(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });

      setSubmitting(false);
    }
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Judges" : "Assign Judges"}
        subtitle={props.isEditing ? "Edit Existing Judges" : "Assign New Judges"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Autocomplete
              multiple
              fullWidth
              options={judgesData || []}
              getOptionLabel={(option) => option.name || ""}
              value={judgesData.filter((judge) =>
                (values.judgeId || []).includes(judge.judgeId)
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Judge Panel"
                  error={Boolean(touched.judgeId && errors.judgeId)}
                  helperText={touched.judgeId && errors.judgeId}
                />
              )}
              onChange={(event, newValue) => {
                setFieldValue(
                  "judgeId",
                  newValue.map((option) => option.judgeId)
                );
              }}
            />

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default JudgeAssignmentForm;
