import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AppNotifTemplates from '../AppNotifTemplate';
import { tokens } from '../../../../theme';
import {useTheme } from "@mui/material";

const AppNotifTemplateView = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    return (
        <Card sx={{ bgcolor:  `${colors.primary[400]}`}}>
            <CardContent>
                <div className="col-xl-12">
                    
                    <AppNotifTemplates/>
                </div>
            </CardContent>
        </Card>
    );
}

export default AppNotifTemplateView;