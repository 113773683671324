import { useState, useEffect, useRef  } from "react";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Modal,
  TextField,
  Button,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { caseManagementUrl } from "../../config";
import {
  generateAndExecuteMutation,
  fetchDataEngine,
} from "../../data/Axios/DynamicService";
import Swal from "sweetalert2";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [sidebarEvents, setSidebarEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal dialog
  const [taskTitle, setTaskTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null); // Add state for end date
  const [cases, setCases] = useState([]); // State for available cases
  const [selectedCaseId, setSelectedCaseId] = useState(""); // State for selected case ID
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userIdFromSession = parseInt(decodedToken.Id);
  

  // Fetch tasks and transform them into calendar events
  useEffect(() => {
    async function fetchTasks() {
      try {
        const returnFields = [
          "id",
          "caseId",
          "asigneeId",
          "description",
          "isActive",
          "statusFlag",
          "dueDate",
          "createdBy",
          "createdDate",
          "updatedBy",
          "updatedDate",
          "companyId",
          "company",
        ];
        const inputFields = { asigneeId: userIdFromSession };
        const tasks = await fetchDataEngine(
          "allCaseTasksByColumnValues",
          "caseTask",
          inputFields,
          returnFields,
          caseManagementUrl.uri
        );
        const events = tasks.map((task) => ({
          id: task.id,
          title: task.description,
          start: new Date(task.createdDate), // Parse the start date correctly
          end: new Date(task.dueDate),   // Parse the end date correctly
          allDay: false,
        }));
        setSidebarEvents(events);
        setCurrentEvents(events);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    }

    fetchTasks();
  }, []);


  useEffect(() => {
    async function fetchCases() {
      try {
        const returnFields = ["id", "title"]; // Adjust fields according to your needs
        const cases = await fetchDataEngine(
          "allCasesDynamic",  // Correct query name
          "caseModel",        // Ensure this matches your schema
          {},                 // No input fields for fetching all cases
          returnFields,
          caseManagementUrl.uri
        );
        setCases(cases);
      } catch (error) {
        console.error("Error fetching cases:", error);
      }
    }

    fetchCases();
  }, []);

  const handleDateClick = (selected) => {
    const clickedcreateDate = selected.start;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (clickedcreateDate >= today) {
      setSelectedDate(clickedcreateDate);
      setEndDate(clickedcreateDate); // Initialize end date to the selected start date
      setIsModalOpen(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "You cannot create tasks for past dates.",
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTaskTitle("");
    setSelectedDate(null);
    setEndDate(null); // Reset end date
    setSelectedCaseId(""); // Reset the selected case ID
  };

  const handleFormSubmit = async () => {
    if (taskTitle.trim() === "" || selectedCaseId === "") {
      return;
    }

    try {
      const newEvent = {
        id: `new-event-${Date.now()}`,
        title: taskTitle,
        start: selectedDate,
        end: endDate, // Use the end date selected by the user
        allDay: false,
      };

      setCurrentEvents((prevEvents) => [...prevEvents, newEvent]);
      
      createTask(newEvent);

      handleCloseModal();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const createTask = async (newEvent) => {
    try {
      const formattedStartDate = newEvent.start.toISOString();
      const formattedEndDate = newEvent.end.toISOString(); // Adjust as needed for your end date logic

      const mutationData = {
        caseId: selectedCaseId, // Use the selected case ID
        description: newEvent.title,
        createdBy: userIdFromSession,
        createdDate: formattedStartDate,
        updatedBy: userIdFromSession,
        dueDate: formattedEndDate,
        companyId: 1,
        asigneeId: userIdFromSession,
      };

      const response = await generateAndExecuteMutation(
        "createCaseTask",
        mutationData,
        "newCaseTask",
        caseManagementUrl.uri
      );

      if (Object.values(response)[0] != null) {
        setSidebarEvents((prevEvents) => [...prevEvents, newEvent]);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Task has been created successfully.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while creating the task. Please try again later.",
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
      // Optionally, you can also handle removing the event from the database here
    }
  };

  return (
    <Box m="20px">
      <Header title="Calendar" subtitle="Full Calendar Interactive Page" />

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
        >
          <Typography variant="h5">Tasks</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {formatDate(event.start, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            events={currentEvents} // Use currentEvents to display events
            eventClick={handleEventClick}
          />
        </Box>
      </Box>
      {/* Modal dialog for adding task */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Task
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="case-select-label">Select Case</InputLabel>
            <Select
              labelId="case-select-label"
              value={selectedCaseId}
              onChange={(e) => setSelectedCaseId(e.target.value)}
              label="Select Case"
            >
              {cases.map((caseItem) => (
                <MenuItem key={caseItem.id} value={caseItem.id}>
                  {caseItem.title} {/* Ensure the field name matches */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Task Title"
            variant="outlined"
            fullWidth
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            value={endDate ? endDate.toISOString().substring(0, 10) : ""}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleFormSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Calendar;
