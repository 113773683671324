import React from "react";
import DynamicTabs from "../../data/DynamicTable/DynamicTabs";

const CompanyManagementModule = () => {
  const rightId = localStorage.getItem("RightIdFromSidebar");
  return (
    <div className="container-fluid">
      <DynamicTabs rightId={rightId} />
    </div>
  );
};

export default CompanyManagementModule;
