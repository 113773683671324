export const CountriesColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "country", headerName: "Country", flex: 1 },
  { field: "countryInitials", headerName: "Country Initials", flex: 1 },
];

export const BranchesColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "branch", headerName: "Branch", flex: 1 },
  { field: "company", headerName: "Company", flex: 1 },
  { field: "region", headerName: "Region", flex: 1 },
];

export const CountiesColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "county", headerName: "County", flex: 1 },
  { field: "countyCode", headerName: "County Code", flex: 1 },
];
