export const allCountries = `
{
    allCountries {
        id
        country
        countryInitials
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}
`;

export const allBranches = ` {
    allBranches {
      id
      branch
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
      regionId
      region
    }
  }`;

export const allCounties = `{
    allCounties {
      id
      county
      countyCode
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }`;
