export const NotificationTemplate = `
 {
    allAppNotificationTemplates {
      id
      messageTemplate
      notificationType
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
  }`;

export const allEmailTemplates =`
{
    allEmailTemplates {
      id
      messageTemplate
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
  }
`;

export const allSmsTemplates = `
{
    allSMSTemplates {
      id
      messageTemplate
      status
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      company
    }
}
`;

export const EmployeePaymentTypes =    `
    {
    allEmployeePaymentTypes {
      id
      paymentType
      metadata
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      company
    }
  }
`;