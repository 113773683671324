import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EmploymentPaymentTypes from '../EmploymentPaymentTypes';
import { tokens } from '../../../../theme';
import {useTheme } from "@mui/material";

const EmploymentPaymentView = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    return (
        <Card sx={{ bgcolor:  `${colors.primary[400]}`}}>
            <CardContent>
                <div>
                    
                    <EmploymentPaymentTypes/>
                </div>
            </CardContent>
        </Card>
    );
}

export default EmploymentPaymentView;