import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import contactImg from "../../../Assets/Images/MenInSuits.jpg";
import { tokens } from "../../../theme";

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    firmSize: "",
    message: "",
  };

  const firmSizes = [
    "1-5 branches",
    "6-10 branches",
    "11-20 branches",
    "More than 20 branches",
  ];

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    firmSize: Yup.string().required("Firm Size is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setStatus }
  ) => {
    try {
      const response = await fetch("http://localhost:5000/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setStatus({ success: true, message: "Message sent successfully" });
        resetForm();
      } else {
        setStatus({
          success: false,
          message: "Something went wrong, please try again later.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus({
        success: false,
        message: "Something went wrong, please try again later.",
      });
    }
    setSubmitting(false);
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "8px",
                height: "90vh",
                mt: 5,
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${contactImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust the alpha value to control the opacity
                  zIndex: 2,
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  zIndex: 3,
                  color: "white",
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Get In Touch
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Book a personalized demo today
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Discover for yourself the power of Sheria Pro:
                </Typography>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>Onboard new clients faster</li>
                  <li>Easily centralize firm operations</li>
                  <li>Reduce time spent on invoicing</li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              mt={6}
              backgroundColor={colors.primary[400]}
              p={4}
              borderRadius={"8px"}
            >
              <Formik
                initialValues={formInitialDetails}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, status }) => (
                  <Form>
                    <Box mb={2}>
                      <Field name="firstName">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box mb={2}>
                      <Field name="lastName">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box mb={2}>
                      <Field name="email">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box mb={2}>
                      <Field name="phone">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            label="Phone No."
                            variant="outlined"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box mb={2}>
                      <Field name="firmSize">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            select
                            label="Firm Size"
                            variant="outlined"
                            fullWidth
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {firmSizes.map((size, index) => (
                              <MenuItem key={index} value={size}>
                                {size}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    </Box>
                    <Box mb={2}>
                      <Field name="message">
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            label="Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        style={{
                          padding: "10px 50px",
                          fontSize: "1rem",
                          transition: "transform 0.2s ease-in-out",
                          backgroundColor: colors.greenAccent[500], // primary color
                          color: "#fff",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "translateY(-2px)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "translateY(0)")
                        }
                      >
                        {isSubmitting ? <CircularProgress size={24} /> : "Send"}
                      </Button>
                    </Box>

                    {status && (
                      <Typography
                        style={{
                          color: status.success ? "green" : "red",
                          marginTop: "16px",
                        }}
                      >
                        {status.message}
                      </Typography>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Contact;
