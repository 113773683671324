export const companyFields = [
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  
  
];
export const departmentFields = [
  {
    name: "departmentName",
    label: "Department Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },



];
export const branchFields = [
  {
    name: "branchName",
    label: "Branch Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },



 

];
export const regionFields = [
  {
    name: "regionName",
    label: "Region Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },


];
export const addOnFields = [
  {
    name: "addOnName",
    label: "Add-On Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "price",
    label: "Price",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const companyAddOnFields = [
 

  {
    name: "addOn",
    label: "Company Add-On",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "purchasedDate",
    label: "Purchased Date",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },

];
export const subscriptionPlanFields = [
  {
    name: "plan",
    label: "Plan",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "price",
    label: "Price",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "durationMonths",
    label: "Duration (Months)",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "storageLimit",
    label: "Storage Limit",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },


];
export const storageUnitFields = [
  {
    name: "unit",
    label: "Unit",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const companyEmailSettingsFields = [
  {
    name: "companyEmail",
    label: "Company Email",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactEmail",
    label: "Contact Email",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactEmailPassword",
    label: "Contact Email Password",
    type: "text", // Use "password" type to hide the password input
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailDisplayName",
    label: "Email Display Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailClient",
    label: "Email Client",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailClientPortNumber",
    label: "Email Client Port Number",
    type: "number", 
    gridColumn: "span 2",
    required: true,
  },
];
