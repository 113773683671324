import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "apollo-link-error";

let hasRefreshed = false;

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
  // if (networkError && networkError.statusCode === 401 && !hasRefreshed) {
  //   hasRefreshed = true;
  //   window.location.reload();
  // }
});
const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("Token not found in localStorage");
    return {};
  }

  const formattedToken = `Bearer ${token.replace(/"/g, "")}`;
  console.log("Formatted Token: ", formattedToken); // Logging the token

  return {
    Authorization: formattedToken,
  };
};

// Middleware to add authorization headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const headers = getAuthHeaders();
  if (headers.Authorization) {
    operation.setContext({
      headers: {
        ...headers,
      },
    });
  } else {
    console.warn("Authorization header is missing");
  }

  return forward(operation);
});

export const createUsersClient = () => {
  const userManagementHttpLink = new HttpLink({
    uri: "https://matrix.mcb.co.ke/gateway/usermanagement",
    //  uri: "https://localhost:7091/graphql/",
  });
  const userHttpLinkWithAuth = authMiddleware.concat(userManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(userHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const createRoleClient = () => {
  const roleManagementHttpLink = new HttpLink({
    uri: "https://matrix.mcb.co.ke/gateway/rolemanagement",
  });
  const roleHttpLinkWithAuth = authMiddleware.concat(roleManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(roleHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const createClientManagementClient = () => {
  const clientManagementHttpLink = new HttpLink({
    uri: "https://matrix.mcb.co.ke/gateway/clientmanagement",
  });
  const clientHttpLinkWithAuth = authMiddleware.concat(
    clientManagementHttpLink
  );

  return new ApolloClient({
    link: onErrorLink.concat(clientHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const createSetupClient = () => {
  const setupManagementHttpLink = new HttpLink({
    uri: "https://matrix.mcb.co.ke/gateway/setupmanagement",
 //   uri: "https://localhost:7266/graphql/",
  });
  const setupHttpLinkWithAuth = authMiddleware.concat(setupManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(setupHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const loginUrl = {
  login: "https://matrix.mcb.co.ke/gateway/login",
  //login: "https://localhost:7173/userservice/login",
  // login:"https://localhost:7091/login/"
};

export const roleManagemenUrl = {
  // uri:"https://localhost:7092/graphql"
  uri: "https://matrix.mcb.co.ke/gateway/rolemanagement",
};

export const caseManagementUrl = {
  // uri: "https://localhost:7146/graphql/",
  uri: "https://matrix.mcb.co.ke/gateway/casemanagement",
};
export const documentManagementUrl = {
  uri: "https://matrix.mcb.co.ke/gateway/documentmanagement",
  baseFilePath:
    "https://matrix.mcb.co.ke/gateway/documentmanagement/ReadDocument?",
  // baseFilePath: "https://matrix.mcb.co.ke/gateway/documentmanagement/ReadDocumentNoSecurity?"
};

export const setupManagementUrl = {
  uri: "https://matrix.mcb.co.ke/gateway/setupmanagement",
};

export const clientManagementUrl = {
  uri: "https://matrix.mcb.co.ke/gateway/clientmanagement",
};

export const userManagementUrl = {
  //uri: "https://matrix.mcb.co.ke/gateway/usermanagement",
  uri: "https://localhost:7091/graphql/",
};

export const AccountsandFinanceurl = {
  // uri: "https://localhost:7173/users/graphql",
  // uri: "https://localhost:7151/graphql/",
  uri: "https://matrix.mcb.co.ke/gateway/accountsmanagement",
};

export const documentUploadUrl = {
  // uri: "https://localhost:7294/documentmanager/UploadCaseDocument",
  uri: "https://matrix.mcb.co.ke/gateway/documentmanagement/UploadCaseDocument",
};

export const companyManagementUrl = {
  uri: "https://matrix.mcb.co.ke/gateway/companymanagement",
  // uri:"https://localhost:7157/graphql/",
};
export const smsservice = {
  uri: "https://matrix.mcb.co.ke/gateway/smsservice",
};
export const notificationservice = {
  uri: "https://matrix.mcb.co.ke/gateway/notificationservice",
};
export const ForgetPasswordApiService = {
  uri: "https://matrix.mcb.co.ke/gateway/forgotpassword",
};
export const deleteDocumentService = {
  uri: "https://matrix.mcb.co.ke/gateway/documentmanagement/DeleteCaseFile",
};
export const MCBSwitchService = {
  uri: "https://matrix.mcb.co.ke/gateway/mcbswitch",
  // uri: "https://localhost:7091/mcbswitch/",
};

export const emailService = {
  uri: "https://matrix.mcb.co.ke/gateway/emailservice",
};
