import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from "../../../../data/SetupManagementData";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
import { allCompanies } from "../../../../data/Axios/queries";
import { companyManagementUrl } from "../../../../config";

const base_url = companyManagementUrl.uri;

const EmailTemplateForm = (props) => {
  const [company, setCompanies] = useState([]);
  const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE, {
  onCompleted: () => {
    props.onClose();
    props.refetch(); 
    swal("Success!", "Email Template has been created successfully", "success");
  },
  onError: () => {
    swal("Error!", "Unable to create Email Template, try again later", "error");
  },
});

  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE, {
    onCompleted: () => {
      props.onClose();
      swal("Success!", "Email Template has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update Email Template, try again later", "error");
    },
  });

  // async function fetchCompanies() {
  //   try {
  //     const data = await graphqlQuery(allCompanies, base_url);
  //     if (data !== null) {
  //       setCompanies(data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching companies:", error);
  //     throw error;
  //   }
  // }

  // useEffect(() => {
  //   fetchCompanies();
  // }, []);

  const initialValues = {
    messageTemplate: props.templateData ? props.templateData.messageTemplate : "",
  };

  const validationSchema = yup.object().shape({
    messageTemplate: yup.string().required("Message Template is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { messageTemplate } = values;

      if (props.isEditing) {
        await updateEmailTemplate({
          variables: {
            id: props.templateData.id,
            messageTemplate,
          },
        });
        swal("Success!", "Email Template has been updated successfully", "success");
        console.log("Email Template updated successfully!");
      } else {
        await createEmailTemplate({
          variables: {
            messageTemplate,
          },
        });
        swal("Success!", "Email Template has been created successfully", "success");
        console.log("Email Template created successfully!");
      }
    } catch (error) {
      swal("Error!", error.message || "An unexpected error occurred.", "error");
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Email Template" : "Create Email Template"}
        subtitle={
          props.isEditing ? "Edit an Existing Email Template" : "Create a New Email Template"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Message Template"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.messageTemplate}
                name="messageTemplate"
                error={touched.messageTemplate && !!errors.messageTemplate}
                helperText={touched.messageTemplate && errors.messageTemplate}
              />
              {/* <FormControl variant="filled" fullWidth error={touched.companyId && Boolean(errors.companyId)}>
                <InputLabel id="companyId-label">Select Company</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  labelId="companyId-label"
                  id="companyId"
                  name="companyId"
                  label="Select Company"
                  value={values.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyId && Boolean(errors.companyId)}
                  helperText={touched.companyId && errors.companyId}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {company.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EmailTemplateForm;
