// usePermissions.js
import { useState, useEffect } from 'react';

const usePermissions = () => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
       const rights = JSON.parse(localStorage.getItem("rights"));
       const cleanedData = rights ? JSON.parse(rights.replace(/\\/g, "")) : [];
    if (!cleanedData) return;

    const perms = {};
    cleanedData.forEach((role) => {
      role.children.forEach((child) => {
        const permKeyBase = child.title.replace(/\s+/g, '');
        const permKeys = Object.keys(child.permissions);

        permKeys.forEach((perm) => {
          const permissionName = `${permKeyBase}.${perm}`;
          perms[permissionName] = !!child.permissions[perm];
        });
      });
    });

    setPermissions(perms);
  }, []);

    const hasPermission = (entity, action) => {
        if (entity && action)
        {
            const permKey = `${entity.replace(/\s+/g, '')}.${action}`;
            return permissions[permKey] || false;
        }
   
  
  };

  return { permissions, hasPermission };
};

export default usePermissions;
