import { Box, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../theme";

const ProgressCircle = ({ progress = "0.75", size = "40" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const angle = progress * 360;
  const adjustedSize = isSmallScreen ? size / 2 : size;
  return (
    <Box
    sx={{
      background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
          conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent[500]} ${angle}deg 360deg),
          ${colors.greenAccent[500]}`,
      borderRadius: "50%",
      width: `${adjustedSize}px`,
      height: `${adjustedSize}px`,
    }}
    />
  );
};

export default ProgressCircle;
