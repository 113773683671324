import React, { useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { PulseLoader } from "react-spinners";
import { Close as CloseIcon } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import swal from "sweetalert";
import { useTheme } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  GET_USERS,
  RESET_PASSWORD,
  ACTIVATE_USER,
  DEACTIVATE_USER,
} from "../../data/userData";
import UsersForm from "./users-form";
import AnchorTemporaryDrawer from "../../components/Drawer";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { transformRoleRights } from "../../data/RBAC/transformRoleRights";
import { AddUserButton, DynamicActions } from "../../data/RBAC/DynamicActions";

function Users() {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { roles, permissions } = transformRoleRights();

  const { loading, error, data, refetch } = useQuery(GET_USERS);

  const [activateUser] = useMutation(ACTIVATE_USER, {
    onCompleted: () => {
      swal("Success!", "User has been activated successfully", "success");
      refetch();
    },
    onError: () => {
      swal("Error!", "Unable to activate user, try again later", "error");
    },
  });

  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    onCompleted: () => {
      swal("Success!", "User has been deactivated successfully", "success");
      refetch();
    },
    onError: () => {
      swal("Error!", "Unable to deactivate user, try again later", "error");
    },
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      swal("Success!", "User password has been reset successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to reset password, try again later", "error");
    },
  });

  if (loading) return;
  // <CircularProgress />
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <PulseLoader size={10} color={"#3f51b5"} />
  </Box>;
  if (error) return <p>Error: {error.message}</p>;

  const dataArray = data.allUsers;

  const handleEdit = (id) => {
    const userToEdit = dataArray.find((user) => user.id === id);
    setEditData(userToEdit);
    setIsEditing(true);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleAddUser = () => {
    setIsEditing(false);
    setEditData(null);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleActivate = (id, isActive, firstName, lastName) => {
    const action = isActive ? "deactivate" : "activate";
    const mutation = isActive ? deactivateUser : activateUser;

    swal({
      title: `Are you sure you want to ${action} ${firstName} ${lastName}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willActivate) => {
      if (willActivate) {
        mutation({ variables: { id } });
      }
    });
  };
  const columns = [
    { field: "id", type: "number", headerName: "ID", flex: 0.5 },
    { field: "username", headerName: "Username", flex: 0.5 },
    { field: "firstName", headerName: "First Name", flex: 0.5 },
    { field: "lastName", headerName: "Last Name", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 0.5 },
    { field: "phone", headerName: "Phone Number", flex: 0.5 },
   // { field: "roleId", headerName: "Role Id", flex: 0.5 },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.5,
      renderCell: ({ row: { isActive } }) => {
        const statusText = isActive ? "Active" : "Blocked";
        const statusColor = isActive ? "green" : "red";
        return (
          <Typography variant="body1" style={{ color: statusColor }}>
            {statusText}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          <DynamicActions
            id={row.id}
            isActive={row.isActive}
            entity="Users"
            onEdit={handleEdit}
            onActivate={() =>
              handleActivate(row.id, row.isActive, row.firstName, row.lastName)
            }
            resetPassword={() => resetPassword({ variables: { id: row.id } })}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box m="1rem">
      <Header title="User Management" subtitle="Manage Users" />
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <AddUserButton onClick={handleAddUser} entity="Users" />
        </Box>
        <DataGrid
          checkboxSelection
          rows={dataArray}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          style={{ minWidth: isMobile ? "auto" : "auto", width: "100%" }}
        />
        <AnchorTemporaryDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          FormComponent={() => (
            <UsersForm
              onClose={() => setDrawerOpen(false)}
              isEditing={isEditing}
              userData={editData}
              refetch={refetch}
            />
          )}
        />

        {isMobile && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullScreen={isMobile}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setDialogOpen(false)}
                aria-label="close"
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UsersForm
                onClick={() => setDialogOpen(false)}
                isEditing={isEditing}
                courtData={editData}
                refetch={refetch}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Box>
  );
}

export default Users;
