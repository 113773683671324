import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl } from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import {
  allBankBranchView,
} from "../../../../data/Axios/queries";
import {
  allBankBranchesViewColumns,
} from "../../../../data/DynamicTable/AccountsColumns";
import BankBranchForm from "./form";
const base_url = AccountsandFinanceurl.uri;
const actions = {
  entity: "Bank Branches",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
};
function BanksBranchTable() {
  return (
    <Box m="2px">
      <DynamicTable
        title="Bank Branches"
        subtitle="Create, view or edit bank branches
        "
        columns={allBankBranchesViewColumns}
        FormComponent={BankBranchForm}
        query={allBankBranchView}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default BanksBranchTable;
