import { useState, useEffect } from "react";

const useSubRightPermissionsHook = () => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const rights = JSON.parse(localStorage.getItem("rights"));
    const cleanedData = rights ? JSON.parse(rights.replace(/\\/g, "")) : [];
    if (!cleanedData) return;

    try {
      const perms = {};

      cleanedData.forEach((role) => {
        role.children?.forEach((child) => {
          if (child.subrights) {
            child.subrights?.forEach((subright) => {
              const subrightPermKeyBase = subright.title.replace(/\s+/g, "");
              const subrightPermKeys = Object.keys(subright.permissions);

              subrightPermKeys.forEach((perm) => {
                const subrightPermissionName = `${subrightPermKeyBase}.${perm}`;
                perms[subrightPermissionName] = !!subright.permissions[perm];
              });
            });
          }
        });
      });

      setPermissions(perms);
    } catch (error) {
      console.error("Failed to parse rights JSON:", error);
    }
  }, []);

  const hasSubRightPermission = (entity, action) => {
    if (entity && action) {
      const permKey = `${entity.replace(/\s+/g, "")}.${action}`;
      const log = permissions[permKey];
      return log || false;
    }
    return false;
  };

  return { permissions, hasSubRightPermission };
};

export default useSubRightPermissionsHook;
