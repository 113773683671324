import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl } from "../../../config";

import { useEffect, useState } from "react";

import { PulseLoader } from "react-spinners";
import { CompanyColumns, CompanyEmailSettingsColumns } from "../../../data/DynamicTable/CompanyManagementColumns";
// import CompanyForm from "./form";
import { allCompanies, allCompanyEmailSettings } from "../../../data/Axios/queries";
import CompanyForm from "./form";
import CompanyEmailSettingsForm from "./form";

const base_url = companyManagementUrl.uri;
const actions = {
  entity: "Companies",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: false,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
};
function CompanyEmailSettingsTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Company Email "
          subtitle="Create, view or edit  Company Email"
          columns={CompanyEmailSettingsColumns}
          FormComponent={CompanyEmailSettingsForm}
          query={allCompanyEmailSettings} //{allAccountCategories}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default CompanyEmailSettingsTable;
