import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Divider } from "@mui/material";
import { tokens } from "../../../../theme";
import { mockTransactions } from "../../../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../../../components/Header";
import StatBox from "./StatBox";
import ProgressCircle from "../../../../components/ProgressCircle";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ChatIcon from '@mui/icons-material/Chat';
import TaskIcon from '@mui/icons-material/Task';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { fetchDataEngine, graphqlQuery } from '../../../../data/Axios/DynamicService';
import { caseManagementUrl, companyManagementUrl } from '../../../../config';
import MainCard from '../../../../components/MainCard';



const base_url = caseManagementUrl.uri;
// const company_url = companyManagementUrl.uri;


export default function CaseDashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
      async function fetchData() {
      try {
        const returnFields = [
       "numberOfTasks",
        "numberOfDocuments",
        "tasksPending",
        "tasksInProgress",
        "tasksOnHold",
        "tasksCompleted",
        "tasksCancelled",
        "tasksFailed"
      ];

      const inputFields = {
        caseId: caseId,
      };
      const Facts = await fetchDataEngine(
        "caseSummary",
        "case",
        inputFields,
        returnFields,
        base_url
      );

      setData(Facts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);


  const fetchUpcomingCaseEvents = async () => {
    try {
      const returnFields = ['eventDate', 'eventType',"description"];

      const inputFields = {
        caseId: caseId,
      };

      const upcoming = await fetchDataEngine(
        "allCaseEventsByColumnValues",
        "caseEvent",
        inputFields,
        returnFields,
        base_url
      );

      setEvents(upcoming);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingCaseEvents();
  }, []);
  
  async function fetchData() {
    try {
      const returnFields = [
        "id",
        "username",
        "statusFlag",
        "assignedDate",
        "firstName",
        "lastName",
        "email",
      ]
      const inputFields = {
        caseId: caseId,
      };
      const TeamAssignmentsData = await fetchDataEngine(
        "teamAssignmentViewsByColumnValues",
        "viewModel",
        inputFields,
        returnFields,
        base_url
      );
      setTeam(TeamAssignmentsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);




  return (
    <>
      <Box sx={{ flexGrow: 1, m: "2px" }}>
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "0.5rem" }}>
                All Case Details
              </Typography>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={'Active tasks'}
                      subtitle="49"
                      progress={` ${data.tasksInProgress}%`}
                      // increase=
                      icon={<GavelIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                    />
                  </Box>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={data.tasksPending}
                      subtitle="Judge Assignment"
                      progress={`+${data.tasksInProgress || 0}%`}
                      // increase=
                      icon={<AssignmentTurnedInIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={data.tasksPending}
                      subtitle="Fact Recordings"
                      progress={` ${data.tasksInProgress}%`}
                      // increase=
                      icon={<FactCheckIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={data.tasksPending}
                      subtitle="Pending Tasks"
                      progress={` ${data.tasksInProgress}%`}
                      // increase=
                      icon={<TaskIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={data.numberOfDocuments}
                      subtitle="Documents"
                      progress={` ${data.tasksInProgress}%`}
                      // increase=
                      icon={<AccountBalanceIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "0.5rem" }}>
              Upcoming events
            </Typography>
            <Box
              sx={{
                bgcolor: colors.primary[400],
                borderRadius: "10px",
                paddingLeft: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box>
                <TableContainer component={Paper} sx={{ bgcolor: colors.primary[400] }}>
                  <Table sx={{ bgcolor: colors.primary[400] }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Event Types</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading && events.length > 0 ? (
                        events.map((event, index) => (
                          <TableRow key={index}>
                            <TableCell>{events.eventType}</TableCell>
                            <TableCell>{events.eventDate}</TableCell>
                            <TableCell>{events.description}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            {loading ? 'Loading...' : 'No events found'}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}
        >
          <Box
            sx={{
              // bgcolor: colors.primary[400],
              // borderRadius: "10px",
              padding: "20px",
              // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h5" fontWeight="600">
              All Case Details
            </Typography>
            <Box >



              <MainCard sx={{ mt: 1, height: "400px" }} content={false}>
                <Box sx={{ p: 3, pb: 0 }}>
                  <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>
                    <ListItemButton divider>

                      <ListItemText primary="Team Assignment" />
                      <Typography variant="h5">{data.numberOfDocuments}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Task Allocation" />
                      <Typography variant="h5">{data.numberOfTasks}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Event Recording " />
                      <Typography variant="h5">{data.tasksOnHold}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Judge Assignment" />
                      <Typography variant="h5">{data.numberOfDocuments}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Fact recording" />
                      <Typography variant="h5">{data.tasksCompleted}</Typography>
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemText primary="Court Assignment" />
                      <Typography variant="h5">{data.numberOfTasks}</Typography>
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemText primary="Document Upload" />
                      <Typography variant="h5">{data.tasksPending}</Typography>
                    </ListItemButton>
                  </List>
                </Box>
              </MainCard>


            </Box>
          </Box>

        </Grid>
        <Grid item xs={12} md={6} >
          <Box sx={{ flexGrow: 1, paddingTop: "40px" }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Box
                  backgroundColor={colors.primary[400]}
                  overflow="auto"
                  // borderBottom={`4px solid ${colors.primary[500]}`}
                  // color={colors.grey[100]}
                  borderRadius="10px"
                  padding="20px"
                // boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <Typography variant="h5" fontWeight="600">
                    Team Assignment
                  </Typography>

                  <Box>
                    <TableContainer component={Paper} sx={{ bgcolor: colors.primary[400] }}>
                      <Table sx={{ bgcolor: colors.primary[400] }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!loading && team.length > 0 ? (
                            team.map((team, index) => (
                              <TableRow key={index}>
                                <TableCell>{team.firstName}</TableCell>
                                <TableCell>{team.lastName}</TableCell>
                                <TableCell>{team.assignedDate}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                {loading ? 'Loading...' : 'No events found'}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Grid>
      </Grid>

    </>



  );
}