import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  Grid,
  CardContent,
  Card,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { CheckCircleOutline } from "@mui/icons-material";
import { PlusOne } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { Suspense, useEffect, useState, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import swal from "sweetalert";
import { allSmsSettings } from "../../../data/Axios/queries";
import { smsservice } from "../../../config";
import { graphqlQuery } from "../../../data/Axios/DynamicService";
function SmsSettings() {
  const theme = useTheme();
  const [smsData, setSmsData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const smsDataResponse = await graphqlQuery(
          allSmsSettings,
          smsservice.uri
        );
        if (smsDataResponse) {
          setSmsData(smsDataResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="Right Management" subtitle="Manage Rights" />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "solid #70d8bd",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Card
              sx={{
                minWidth: 275,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid #70d8bd"
                    : "1px solid #1F2A40",
                borderRadius: "0.75rem",
                bgcolor:
                  theme.palette.mode === "light"
                    ? colors.primary[900]
                    : colors.blueAccent[700],
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: colors.greenAccent[400],
                  }}
                  gutterBottom
                >
                  Promotional Amount
                </Typography>
                <Typography variant="h5" component="div">
                  {smsData.promotionalAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                minWidth: 275,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid #70d8bd"
                    : "1px solid #1F2A40",
                borderRadius: "0.75rem",
                bgcolor:
                  theme.palette.mode === "light"
                    ? colors.primary[900]
                    : colors.blueAccent[700],
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: colors.greenAccent[400],
                  }}
                  gutterBottom
                >
                  Transactional Amount
                </Typography>
                <Typography variant="h5" component="div">
                  {smsData.transactionalAmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                justifyContent: "center",
                minWidth: 275,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid #70d8bd"
                    : "1px solid #1F2A40",
                borderRadius: "0.75rem",
                bgcolor:
                  theme.palette.mode === "light"
                    ? colors.primary[900]
                    : colors.blueAccent[700],
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: colors.greenAccent[400],
                  }}
                  gutterBottom
                >
                  Sms Limit
                </Typography>
                <Typography variant="h5" component="div">
                  {smsData.smsLimit}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default SmsSettings;
