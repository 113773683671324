

import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { allPartyTypes } from "../../../../data/Queries/SetupManagement/OtherSetupsQueries";
import { PartyTypeColumn } from "../../../../data/Columns/otherSetupColumns";
import PartyTypeForm from "./Form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Party Type",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function PartyTypes() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Party Types"
          subtitle="Manage Party Types"
          columns={PartyTypeColumn}
          FormComponent={PartyTypeForm}
          query={allPartyTypes}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default PartyTypes;