const fetchDataSuccess = (data) => ({
    type: 'FETCH_DETAILS_SUCCESS',
    payload: data
});

const fetchDataError = (error) => ({
    type: 'FETCH_DETAILS_ERROR',
    payload: error
});

const updateDataSuccess = (data) => ({
    type: 'UPDATE_DETAILS_SUCCESS',
    payload: data
});

const updateDataError = (error) => ({
    type: 'UPDATE_DETAILS_ERROR',
    payload: error
});

const updateActiveDocumentSuccess = (data) => ({
    type: 'UPDATE_ACTIVE_DOCUMENT',
    payload: data
});

const documentTableLoadingState = (data) => ({
    type: 'REFRESH_DOCUMENTS_TABLE',
    payload: data
});


const actionTypes = {
    FETCH_DETAILS_SUCCESS: fetchDataSuccess,
    FETCH_DETAILS_ERROR: fetchDataError,
    UPDATE_DETAILS_SUCCESS: updateDataSuccess,
    UPDATE_DETAILS_ERROR: updateDataError,
    UPDATE_ACTIVE_DOCUMENT: updateActiveDocumentSuccess,
    REFRESH_DOCUMENTS_TABLE: documentTableLoadingState,
};


export const SaveDataToStore = (data) => {
    return async (dispatch) => {
        try {
            if (data) {
                await dispatch(fetchDataSuccess(data));
                return Promise.resolve();
            }
        } catch (error) {
            await dispatch(fetchDataError(error));
            return Promise.reject(error);
        }
    };
};

export const UpdateDataToStore = (actionKey, data) => {
    return async (dispatch) => {
        try {
            await dispatch(actionTypes[actionKey](data));
            return Promise.resolve();
        } catch (error) {
            await dispatch(updateDataError(error));
            return Promise.reject(error);
        }
    };
};

