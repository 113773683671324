import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField, useMediaQuery,
  useTheme
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_COURT, UPDATE_COURT } from "../../../../data/SetupManagementData";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
import {allCounties } from "../../../../data/Axios/queries";
import { tokens } from "../../../../theme";
import { setupManagementUrl } from "../../../../config";

const setup_url = setupManagementUrl.uri;
// const base_url = companyManagementUrl.uri;

const CourtForm = (props) => {
  // const [company, setCompanies] = useState([]);
  const [county, setCounties] = useState([]);
  const [createCourt] = useMutation(CREATE_COURT, {
    onCompleted: () => {
      props.onClose();
      props.refetch();//edited
      swal("Success!", "Court has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create court, try again later", "error");
    },
  });

  // async function fetchCompanies() {
  //   try {
  //     const data = await graphqlQuery(allCompanies, base_url);
  //     if (data !== null) {
  //       // setCompanies(data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching companies:", error);
  //     throw error;
  //   }
  // }

  async function fetchCounties() {
    try {
      const data = await graphqlQuery(allCounties, setup_url);
      if (data !== null) {
        setCounties(data);
      }
    } catch (error) {
      console.error("Error fetching counties:", error);
      throw error;
    }
  }

  useEffect(() => {
    
    fetchCounties();
  }, []);


  const [updateCourt] = useMutation(UPDATE_COURT, {
    onCompleted: () => {
      props.onClose()
      props.refetch()//edited
      swal("Success!", "Court has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update court, try again later", "error");
    },
  });

  const initialValues = {
    court: "",
    country: "Kenya",
  };

  const validationSchema = yup.object().shape({
    court: yup.string().required("Court is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        court,
        // courtTypeId,
        // countryId,
        countyId,
        // subCountyId,
        // latitude,
        // longitude,
        // createdBy,
        // companyId,
      } = values;

      if (props.isEditing) {
        props.onClose();
        await updateCourt({
          variables: {
            court,
            // courtTypeId,
            // countryId,
            countyId,
            // subCountyId,
            // latitude,
            // longitude,
            // createdBy,
            // companyId,
          },
        });
        console.log("Court created successfully!");
      }else {
        await createCourt({
          variables: {
            court,
          },
        });
        console.log("Court type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
   const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title={props.isEditing ? "Edit Court" : "Create Court"}
        subtitle={
          props.isEditing ? "Edit an Existing Court" : "Create a New Court"
        }
        />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px" gridTemplateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Court"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.court}
                name="court"
                error={touched.court && !!errors.court}
                helperText={touched.court && errors.court}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                value={values.country}
                name="country"
                disabled
              />
              {/* <FormControl
                variant="filled"
                fullWidth
                error={touched.companyId && Boolean(errors.companyId)}
              >
                <InputLabel id="companyId-label">Select Company</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  labelId="companyId-label"
                  id="companyId"
                  name="companyId"
                  label="Select Company"
                  value={values.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyId && Boolean(errors.companyId)}
                  helperText={touched.companyId && errors.companyId}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {company.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <FormControl
                variant="filled"
                fullWidth
                error={touched.countyId && Boolean(errors.countyId)}
              >
                <InputLabel id="countyId-label">Select County</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  labelId="countyId-label"
                  id="countyId"
                  name="countyId"
                  label="Select County"
                  value={values.countyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.countyId && Boolean(errors.countyId)}
                  helperText={touched.countyId && errors.countyId}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select County
                  </MenuItem>
                  {county.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.county}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent={isMobile ? "center" : "flex-end"} mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                // sx={{
                //   backgroundColor: colors.greenAccent[500],
                //   borderRadius: "4px",
                // }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CourtForm;
