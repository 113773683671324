import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
} from "../../../../data/SetupManagementData"; // Import your GraphQL queries and mutations

const DocumentTypeForm = (props) => {
  const [createDocumentType] = useMutation(CREATE_DOCUMENT_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Document type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create document type, try again later", "error");
    },
  });

  const [updateDocumentType] = useMutation(UPDATE_DOCUMENT_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Document type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update document type, try again later", "error");
    },
  });

  const initialValues = {
    id: props.documentTypeData ? props.documentTypeData.id : null,
    documentType: props.documentTypeData ? props.documentTypeData.documentType : null,
    createdDate: props.documentTypeData ? props.documentTypeData.createdDate : null,
    createdBy: props.documentTypeData ? props.documentTypeData.createdBy : null,
    updatedBy: props.documentTypeData ? props.documentTypeData.updatedBy : null,
    updatedDate: props.documentTypeData ? props.documentTypeData.updatedDate : null,
  };

  const validationSchema = yup.object().shape({
    documentType: yup.string().required("Document type is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (props.isEditing) {
        await updateDocumentType({
          variables: {
            id: props.documentTypeData.id,
            documentType: values.documentType,
            createdDate: props.documentTypeData.createdDate,
            createdBy: props.documentTypeData.createdBy,
            updatedBy: props.documentTypeData.updatedBy,
            updatedDate: new Date().toISOString(),
          },
        });
        console.log("Document type updated successfully!");
      } else {
        await createDocumentType({
          variables: {
            documentType: values.documentType,
            createdDate: new Date().toISOString(),
            createdBy: props.userId,
            updatedBy: props.userId,
            updatedDate: new Date().toISOString(),
          },
        });
        console.log("Document type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Document Type" : "Create Document Type"}
        subtitle={
          props.isEditing ? "Edit an Existing Document Type" : "Create a New Document Type"
        }
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Document Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.documentType}
                name="documentType"
                error={touched.documentType && !!errors.documentType}
                helperText={touched.documentType && errors.documentType}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default DocumentTypeForm;
