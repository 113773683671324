import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import {
  companyManagementUrl,
  emailService,
  setupManagementUrl,
} from "../../../config";

import { allAddOns, allAdhocEmails } from "../../../data/Axios/queries";

import { EmailsByCompanyColumns } from "../../../data/DynamicTable/AdhocColumns";
import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";

const email_base_url = emailService.uri;
const actions = {
  entity: "Adhoc Emails",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: false,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function AdhocEmails() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MultilineTextCell = ({ value }) => {
    return (
      <TextareaAutosize
        aria-label="body content"
        minRows={3}
        style={{
          color: "white",
          width: "100%",
          resize: "none",
          border: "none",
          padding: "8px",
          backgroundColor: `${colors.primary[400]}`,
        }}
        value={value}
        readOnly
      />
    );
  };
  const columns = [
    ...EmailsByCompanyColumns,

    {
      field: "body",
      headerName: "Body",
      flex: 3,
      renderCell: (params) => <MultilineTextCell value={params.value} />,
    },
    { field: "sentDateTime", headerName: "Sent Date Time", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Adhoc Emails"
          subtitle="Manage adhoc emails"
          columns={columns}
          // FormComponent={AddOnForm}
          query={allAdhocEmails}
          base_url={email_base_url}
          actions={actions}
          getRowId={(row) => row.messageId}
        />
      </>
    </Box>
  );
}

export default AdhocEmails;
