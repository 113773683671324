import PeopleIcon from '@mui/icons-material/People';
import BackupIcon from '@mui/icons-material/Backup';
import BuildIcon from '@mui/icons-material/Build';
import SecurityIcon from '@mui/icons-material/Security';
import BarChartIcon from '@mui/icons-material/BarChart';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudIcon from '@mui/icons-material/Cloud';
import person1 from "../Assets/Images/person1.jpg";
import person2 from "../Assets/Images/person2.jpeg";
import person3 from "../Assets/Images/person3.jpeg";
import person4 from "../Assets/Images/person4.jpeg";
import person5 from "../Assets/Images/person5.jpeg";
import person6 from "../Assets/Images/person6.jpeg";
import person7 from "../Assets/Images/person7.jpeg";
import person8 from "../Assets/Images/person8.jpeg";

export const navItems = [
  { label: "Features", href: "#features" },
  { label: "Workflow", href: "#workflow" },
  { label: "Testimonials", href: "#testimonials" },
];

export const testimonials = [
  {
    user: "John Doe",
    company: "Stellar Solutions",
    image: person1,
    text: "I am extremely satisfied with the services provided by Sheria Pro. From the moment I engaged with their team, I experienced a level of responsiveness, professionalism, and dedication that truly set them apart. They not only met but exceeded my expectations at every turn, delivering results that far surpassed what I had hoped for. I highly recommend Sheria Pro to anyone in need of top-tier legal services. Thank you for your exceptional work!",
  },
  {
    user: "Jane Smith",
    company: "Blue Horizon Technologies",
    image: person4,
    text: "I'm incredibly satisfied with the exceptional service from Sheria Pro! Their dedicated team handled my case with professionalism and efficiency, ensuring every detail was addressed. From the initial consultation to the final resolution, their expertise and commitment were evident. Thanks to Sheria Pro, my case was resolved perfectly, exceeding my expectations. I highly recommend their services to anyone seeking reliable legal assistance. Thank you for your outstanding work!",
  },
  {
    user: "David Johnson",
    company: "Quantum Innovations",
    image: person2,
    text: "Working with this company was a pleasure. Their attention to detail and commitment to excellence are commendable. I would highly recommend them to anyone looking for top-notch Legal service.",
  },
  {
    user: "Everlyne Brown",
    company: "Fusion Dynamics",
    image: person5,
    text: "Working with the team at SheriaPro Legal Service Company was a game-changer for our project. Their attention to detail and innovative solutions helped us achieve our goals faster than we thought possible. We are grateful for their expertise and professionalism!",
  },
  {
    user: "Michael Wilson",
    company: "Visionary Creations",
    image: person3,
    text: "Sheria Pro exceeded all my expectations in handling my case. Their professionalism and expertise were evident from the start. They provided clear, concise advice and kept me informed every step of the way. Thanks to their hard work and attention to detail, my case was resolved successfully and swiftly. I highly recommend Sheria Pro to anyone in need of top-notch legal services. Thank you for your outstanding dedication and results!",
  },
  {
    user: "Emily Davis",
    company: "Synergy Systems",
    image: person6,
    text: "Sheria Pro provided exceptional service, resolving my case with professionalism and expertise. They were attentive, communicative, and dedicated to achieving the best outcome. Their guidance and support made a challenging situation manageable. I highly recommend Sheria Pro for anyone needing reliable and effective legal assistance. Thank you for your outstanding work!",
  },
  {
    user: "Scholar Maryanne",
    company: "Synergy Systems",
    image: person7,
    text: "Sheria Pro provided exceptional service, resolving my case with professionalism and expertise. They were attentive, communicative, and dedicated to achieving the best outcome. Their guidance and support made a challenging situation manageable. I highly recommend Sheria Pro for anyone needing reliable and effective legal assistance. Thank you for your outstanding work!",
  },
  {
    user: "Carolyn Worth",
    company: "Synergy Systems",
    image: person8,
    text: "Sheria Pro provided exceptional service, resolving my case with professionalism and expertise. They were attentive, communicative, and dedicated to achieving the best outcome. Their guidance and support made a challenging situation manageable. I highly recommend Sheria Pro for anyone needing reliable and effective legal assistance. Thank you for your outstanding work!",
  },
];

export const features = [
  {
    icon: <PeopleIcon fontSize="large" />,
    text: "Unlimited System Users",
    description: "Add as many users as your firm needs without any extra cost. Manage large teams effortlessly with our scalable user management system.",
  },
  {
    icon: <BackupIcon fontSize="large" />,
    text: "Daily Automatic Data Backup",
    description: "Secure and automatic daily backups to protect your data. Never worry about losing important information with our reliable backup solutions.",
  },
  {
    icon: <BuildIcon fontSize="large" />,
    text: "Customizable Modules",
    description: "Tailor the system to fit your firm's specific requirements. Modify and adapt modules to enhance your workflow and efficiency.",
  },
  {
    icon: <SecurityIcon fontSize="large" />,
    text: "Role-based Privileges",
    description: "Ensure security with access control based on user roles. Manage permissions effectively to maintain data integrity and confidentiality.",
  },
  {
    icon: <SecurityIcon fontSize="large" />,
    text: "Security and Privacy",
    description: "State-of-the-art security measures to protect your information. We prioritize your data privacy with advanced encryption and protection protocols.",
  },
  {
    icon: <BarChartIcon fontSize="large" />,
    text: "Graphical Analysis",
    description: "Visualize your data with advanced graphical analysis tools. Make informed decisions with clear, comprehensive data insights and trends.",
  },
  {
    icon: <PictureAsPdfIcon fontSize="large" />,
    text: "Exportation of Reports",
    description: "Export reports to PDF and Excel formats effortlessly. Share and analyze your reports with ease using our versatile export options.",
  },
  {
    icon: <CloudIcon fontSize="large" />,
    text: "Access from Multiple Branches",
    description: "Access your system securely from various locations. Maintain consistent operations across multiple branches with centralized data access.",
  },
];

export const checklistItems = [
  {
    title: "Legal Service Made Easy",
    description: "Simplify your legal needs with our user-friendly application designed to make legal services accessible.",
  },
  {
    title: "Review Case Progress Without Worry",
    description: "Monitor the status and performance of your legal cases effortlessly with our tracking tools.",
  },
  {
    title: "Apply for Our Service Anytime",
    description: "Access our legal services at your convenience, with the ability to apply for assistance at any time.",
  },
  {
    title: "Services Rendered to Your Expectations",
    description: "Receive top-notch legal services tailored to meet and exceed your expectations.",
  },  
];

export const resourcesLinks = [
  { href: "#", text: "User Guide" },
  { href: "#", text: "Documentation" },
  { href: "#", text: "Training Videos" },
  { href: "#", text: "API Documentation" },
  { href: "#", text: "Community Forums" },
];

export const platformLinks = [
  { href: "#", text: "Features" },
  { href: "#", text: "Supported Devices" },
  { href: "#", text: "System Requirements" },
  { href: "#", text: "Downloads" },
  { href: "#", text: "Release Notes" },
];

export const communityLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];