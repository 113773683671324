export const DepartmentsColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
];

export const DesignationColumn = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "designation", headerName: "Designation", flex: 1 },
];

export const DocumentTypeColumn = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "documentType", headerName: "Document Type", flex: 1 },
];

export const EventTypeColumn = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "eventType", headerName: "event Type", flex: 1 },
];

export const FactCategoryCoumn = [
    { field: "categoryId", headerName: "ID", flex: 1 },
    { field: "categoryName", headerName: "Category Name", flex: 1 },
];

export const PartyTypeColumn = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "partyType", headerName: "Party Type", flex: 1 },
];