import React from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_PARTY_TYPE,
  UPDATE_PARTY_TYPE,
  ALL_PARTY_TYPES,
} from "../../../../data/SetupManagementData";

const PartyTypeForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const { loading: partyTypeLoading, error: partyTypeError, data: partyTypeData } = useQuery(ALL_PARTY_TYPES);

  const [createPartyType] = useMutation(CREATE_PARTY_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Party Type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create party type, try again later", "error");
    },
  });

  const [updatePartyType] = useMutation(UPDATE_PARTY_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Party Type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update party type, try again later", "error");
    },
  });

  const initialValues = {
    partyType: props.partyTypeData ? props.partyTypeData.partyType : "",
  };

  const validationSchema = yup.object().shape({
    partyType: yup.string().required("Party Type is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { partyType } = values;

      if (props.isEditing) {
        await updatePartyType({
          variables: {
            id: props.partyTypeData.id,
            partyType,
          },
        });
        console.log("Party Type updated successfully!");
      } else {
        await createPartyType({
          variables: {
            partyType,
          },
        });
        console.log("Party Type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Party Type" : "Create Party Type"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Party Type"
            : "Create a New Party Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Party Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partyType}
                name="partyType"
                error={touched.partyType && !!errors.partyType}
                helperText={touched.partyType && errors.partyType}
              />
              {partyTypeLoading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : partyTypeError ? (
                <div>Error loading party type</div>
              ) : null}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PartyTypeForm;
