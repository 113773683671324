import { MegaModel } from "../models/CustomModel";

const initialState = {
    customData: MegaModel,
    status: 'idle',
    error: null,
};

export default function CustomReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_DETAILS_SUCCESS':
            return {
                ...state,
                customData: action.payload,
                status: 'success',
            };
        case 'FETCH_DETAILS_ERROR':
            return {
                ...state,
                error: action.payload,
                status: 'error',
            };
        case 'UPDATE_DETAILS_SUCCESS':
            return {
                ...state,
                customData: action.payload,
                status: 'success',
            };
        case 'UPDATE_ACTIVE_DOCUMENT': {
            const updatedDocument = action.payload;
            return {
                ...state,
                customData: {
                    ...state.customData,
                    caseManagementModel: {
                        ...state.customData.caseManagementModel,
                        documentUploads: {
                            ...state.customData.caseManagementModel.documentUploads,
                            currentDocument: updatedDocument,
                        },
                    },
                },
                status: 'success',
            };
        }
        case 'REFRESH_DOCUMENTS_TABLE': {
            const currentState = action.payload;
            return {
                ...state,
                customData: {
                    ...state.customData,
                    caseManagementModel: {
                        ...state.customData.caseManagementModel,
                        documentUploads: {
                            ...state.customData.caseManagementModel.documentUploads,
                            loadingState: currentState,
                        },
                    },
                },
                status: 'success',
            };
        }
        case 'UPDATE_DETAILS_ERROR':
            return {
                ...state,
                error: action.payload,
                status: 'error',
            };
        default:
            return state;
    }
}