
import { Box } from "@mui/material";

import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@emotion/react";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { allLeadSources } from "../../../../data/Queries/SetupManagement/LeadStatusQueries";
import LeadSourceForm from "./Form";
import { LeadSourcesColumns } from "../../../../data/Columns/leadstatusColumns";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Lead Status",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function LeadSources() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Lead Sources"
          subtitle="Manage Lead Sources"
          columns={LeadSourcesColumns}
          FormComponent={LeadSourceForm}
          query={allLeadSources}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default LeadSources;