import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  ALL_EMPLOYEE_PAYMENT_TYPES,
  CREATE_EMPLOYEE_PAYMENT_TYPE,
  UPDATE_EMPLOYEE_PAYMENT_TYPE,
} from "../../../../data/SetupManagementData";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
import { allCompanies } from "../../../../data/Axios/queries";
import { companyManagementUrl } from "../../../../config";

const base_url = companyManagementUrl.uri;

const EmploymentPaymentTypeForm = (props) => {
  const [company, setCompanies] = useState([]);
  const {
    loading,
    error,
  } = useQuery(ALL_EMPLOYEE_PAYMENT_TYPES);

  const [createPaymentType] = useMutation(CREATE_EMPLOYEE_PAYMENT_TYPE, {
    onCompleted: (data) => {
      props.onClose();
      props.refetch(); 
      swal("Success!", "Payment type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create payment type, try again later", "error");
    },
  });

  const [updatePaymentType] = useMutation(UPDATE_EMPLOYEE_PAYMENT_TYPE, {
    onCompleted: () => {
      props.onClose();
      props.refetch(); 
      swal("Success!", "Payment type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update payment type, try again later", "error");
    },
  });
  async function fetchCompanies() {
    try {
      const data = await graphqlQuery(allCompanies, base_url);
      if (data !== null) {
        setCompanies(data);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      throw error;
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  const initialValues = {
    paymentType: props.paymentTypeData ? props.paymentTypeData.paymentType : "",
    metadata: props.paymentTypeData ? props.paymentTypeData.metadata : "",
  };

  const validationSchema = yup.object().shape({
    paymentType: yup.string().required("Payment Type is required"),
    metadata: yup.string().required("Metadata is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { paymentType, metadata } = values;

      if (props.isEditing) {
        await updatePaymentType({
          variables: {
            id: props.paymentTypeData.id,
            paymentType,
            metadata,
          },
        });
        console.log("Payment Type updated successfully!");
      } else {
        await createPaymentType({
          variables: {
            paymentType,
            metadata,
          },
        });
        console.log("Payment Type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Payment Type" : "Create Payment Type"}
        subtitle={
          props.isEditing ? "Edit an Existing Payment Type" : "Create a New Payment Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Payment Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paymentType}
                name="paymentType"
                error={touched.paymentType && !!errors.paymentType}
                helperText={touched.paymentType && errors.paymentType}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Metadata"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.metadata}
                name="metadata"
                error={touched.metadata && !!errors.metadata}
                helperText={touched.metadata && errors.metadata}
              />
              {/* <FormControl variant="filled" fullWidth error={touched.companyId && Boolean(errors.companyId)}>
                <InputLabel id="companyId-label">Select Company</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  labelId="companyId-label"
                  id="companyId"
                  name="companyId"
                  label="Select Company"
                  value={values.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyId && Boolean(errors.companyId)}
                  helperText={touched.companyId && errors.companyId}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {company.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EmploymentPaymentTypeForm;
