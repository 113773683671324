import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl } from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import { allAccountTypes } from "../../../../data/Axios/queries";
const base_url = AccountsandFinanceurl.uri;
const actions = {
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false, 
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function AccountTypesTable() {
  return (
    <Box m="2px">
      <DynamicTable
        title="All Account Types"
        subtitle="View account types"
        columns={AccountTypesColumns}
        //FormComponent={CasesForm}
        query={allAccountTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default AccountTypesTable;
