import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_DESIGNATION, UPDATE_DESIGNATION } from "../../../../data/SetupManagementData";
import useMediaQuery from "@mui/material/useMediaQuery";

const DesignationForm = (props) => {
  const [createDesignation] = useMutation(CREATE_DESIGNATION, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Designation has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create designation, try again later", "error");
    },
  });

  const [updateDesignation] = useMutation(UPDATE_DESIGNATION, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Designation has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update designation, try again later", "error");
    },
  });
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const initialValues = {
    designation: props.designationData ? props.designationData.designation : "",
  };

  const validationSchema = yup.object().shape({
    designation: yup.string().required("Designation is required"),
  });
 
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { designation } = values;

      if (props.isEditing) {
        await updateDesignation({
          variables: {
            id: props.designationData.id,
            designation,
          },
        });
        console.log("Designation updated successfully!");
      } else {
        await createDesignation({
          variables: {
            designation,
          },
        });
        console.log("Designation created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Designation" : "Create Designation"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Designation"
            : "Create a New Designation"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Designation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.designation}
                name="designation"
                error={touched.designation && !!errors.designation}
                helperText={touched.designation && errors.designation}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default DesignationForm;
