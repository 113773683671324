// CourtTypeView.js
import React from "react";
import DynamicCard from "./DynamicComponent";
import AccountTypesTable from "../Tables/AllAccountTypes/Index";
import AccountCategoriesTable from "../Tables/AccountCategories";
import AccountSubcategoriesTable from "../Tables/AcocuntSubcategories";
import AccountsTable from "../Tables/Accounts";
import BanksAccountTable from "../Tables/BankAccounts";
import BanksBranchTable from "../Tables/BankBranch";
import BanksTable from "../Tables/Banks";
import FiscalYearTable from "../Tables/FiscalYears";
import PaymentTypesTable from "../Tables/PaymentTypes";
import TaxAuthorityTable from "../Tables/TaxAuthorities";
import TaxRateTable from "../Tables/TaxRates";
import CompanyTable from "../../CompanyManagement/Company";
import RegionTable from "../../CompanyManagement/Regions";
import BranchTable from "../../CompanyManagement/Branch";
import DepartmetsTable from "../../CompanyManagement/Departments";
import AddOnsTable from "../../CompanyManagement/Add-Ons";
import CompanyAddOnTable from "../../CompanyManagement/CompanyAdd-ons";
import StorageUnitsTable from "../../CompanyManagement/StoreageUnits";
import SubscriptionPlansTable from "../../CompanyManagement/SubscriptionPlans";
import SmsSettings from "../../CompanyManagement/SmsSettings";
import CompanyEmailSettingsTable from "../../CompanyManagement/CompanyEmailSettings";
import EmailSettingsForm from "../../CompanyManagement/CompanyEmailSettings/EditableForm";
import AdhocSms from "../../adhoc/adhocsms";
import AdhocEmails from "../../adhoc/adhocemail";
import LeadSources from "../../Setup Management/LeadSetups/LeadSources";
import LeadStatuses from "../../Setup Management/LeadSetups/LeadStatus";
import Courts from "../../Setup Management/SystemSetups/Courts";
import Designations from "../../Setup Management/OtherSetups/Designation";
import Departments from "../../Setup Management/OtherSetups/Department";
import DocumentTypes from "../../Setup Management/OtherSetups/Document/Index";
import Events from "../../Setup Management/OtherSetups/EventType";
import FactCategories from "../../Setup Management/OtherSetups/FactCategory";
import PartyTypes from "../../Setup Management/OtherSetups/PartyType";
import Counties from "../../Setup Management/GeographySetups/County";
import Country from "../../Setup Management/GeographySetups/Country";

export const AccountTypesView = () => {
  return (
    <DynamicCard>
      <AccountTypesTable />
    </DynamicCard>
  );
};

export const AccountCategoryView = () => {
  return (
    <DynamicCard>
      <AccountCategoriesTable />
    </DynamicCard>
  );
};
export const AccountSubcategoryView = () => {
  return (
    <DynamicCard>
      <AccountSubcategoriesTable />
    </DynamicCard>
  );
};
export const AccountsView = () => {
  return (
    <DynamicCard>
      <AccountsTable />
    </DynamicCard>
  );
};

export const BankAccountsView = () => {
  return (
    <DynamicCard>
      <BanksAccountTable />
    </DynamicCard>
  );
};
export const BankBranchView = () => {
  return (
    <DynamicCard>
      <BanksBranchTable />
    </DynamicCard>
  );
};
export const BanksView = () => {
  return (
    <DynamicCard>
      <BanksTable />
    </DynamicCard>
  );
};
export const FiscalYearsView = () => {
  return (
    <DynamicCard>
      <FiscalYearTable />
    </DynamicCard>
  );
};
export const PaymentTypesView = () => {
  return (
    <DynamicCard>
      <PaymentTypesTable />
    </DynamicCard>
  );
};
export const TaxAuthoritiesView = () => {
  return (
    <DynamicCard>
      <TaxAuthorityTable />
    </DynamicCard>
  );
};
export const TaxRatesView = () => {
  return (
    <DynamicCard>
      <TaxRateTable />
    </DynamicCard>
  );
};
export const CompanyView = () => {
  return (
    <DynamicCard>
      <CompanyTable />
    </DynamicCard>
  );
};

export const RegionsView = () => {
  return (
    <DynamicCard>
      <RegionTable />
    </DynamicCard>
  );
};

export const BankBranchesView = () => {
  return (
    <DynamicCard>
      <BranchTable />
    </DynamicCard>
  );
};

export const DepartmentView = () => {
  return (
    <DynamicCard>
      <DepartmetsTable />
    </DynamicCard>
  );
};

export const AddOnView = () => {
  return (
    <DynamicCard>
      <AddOnsTable />
    </DynamicCard>
  );
};

export const CompanyAddOnsView = () => {
  return (
    <DynamicCard>
      <CompanyAddOnTable />
    </DynamicCard>
  );
};

export const SubscriptionPlansView = () => {
  return (
    <DynamicCard>
      <SubscriptionPlansTable />
      {/* <SubscriptionPlansTable /> */}
    </DynamicCard>
  );
};

export const StorageUnitsView = () => {
  return (
    <DynamicCard>
      <StorageUnitsTable />
    </DynamicCard>
  );
};
export const SmsSettingsView = () => {
  return (
    <DynamicCard>
      <SmsSettings />
    </DynamicCard>
  );
};
export const EmailSettingsView = () => {
  return (
    <DynamicCard>
      {/* // <CompanyEmailSettingsTable /> */}
      <EmailSettingsForm />
    </DynamicCard>
  );
};
export const AdhocSmsView = () => {
  return (
    <DynamicCard>
      <AdhocSms />
    </DynamicCard>
  );
};
export const AdhocEmailsView = () => {
  return (
    <DynamicCard>
      <AdhocEmails />
    </DynamicCard>
  );
};
export const LeadSourcesView = () => {
  return (
    <DynamicCard>
      <LeadSources />
    </DynamicCard>
  );
};
export const LeadStatusView = () => {
  return (
    <DynamicCard>
      <LeadStatuses />
    </DynamicCard>
  );
};
export const CourtsView = () => {
  return (
    <DynamicCard>
      <Courts />
    </DynamicCard>
  );
};

export const DepartmentViewView = () => {
  return (
    <DynamicCard>
      <Departments />
    </DynamicCard>
  );
};

export const DesignationView = () => {
  return (
    <DynamicCard>
      <Designations />
    </DynamicCard>
  );
};

export const DocumentView = () => {
  return (
    <DynamicCard>
      <DocumentTypes />
    </DynamicCard>
  );
};

export const EventView = () => {
  return (
    <DynamicCard>
      <Events />
    </DynamicCard>
  );
};

export const FactCategoryView = () => {
  return (
    <DynamicCard>
      <FactCategories />
    </DynamicCard>
  );
};


export const PartyTypesView = () => {
  return (
    <DynamicCard>
      <PartyTypes />
    </DynamicCard>
  );
};

export const CountryView = () => {
  return (
    <DynamicCard>
      <Country />
    </DynamicCard>
  );
};
export const CountyView = () => {
  return (
    <DynamicCard>
      <Counties />
    </DynamicCard>
  );
};
