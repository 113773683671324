import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Cases from "../CaseManagement/Case";
import { useState, useEffect } from "react";
import { graphqlQuery } from "../../data/Axios/DynamicService";
import {
  caseTaskForDashboard,
  mainDashboardQuery,
  recentCaseTasks,
} from "../../data/Axios/queries";
import { caseManagementUrl, companyManagementUrl } from "../../config";
import MainCard from "../../components/MainCard";
import OrderTable from "./OrdersTable";
import WorkIcon from "@mui/icons-material/Work";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [task, setTasks] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    async function fetchAllData() {
      try {
        if (isMounted) {
          const Response = await graphqlQuery(
            mainDashboardQuery,
            companyManagementUrl.uri
          );
          if (Response) {
            setData(Response);
          }
          
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <Grid container rowSpacing={2.5} columnSpacing={2.75} padding={"1rem"}>
        {/* row 1 */}
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Header title="Dashboard" subtitle="Welcome to SheriaPro Dashboard" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link
            // to="/Cases"
            style={{ textDecoration: "none", color: "inherit" }}
            onclick={() => navigate("/Cases")}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.numberOfNewClients}
                subtitle="Cases"
                progress="0.75"
                increase={`+${data.courtCasePercentage || 0}%`}
                icon={
                  <WorkHistoryIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
              />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link
            href="/corporate-clients"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.numberOfCorporateClients}
                subtitle="Corporate Clients"
                progress="0.50"
                increase={`+${data.corporateClientPercentage || 0}%`}
                icon={
                  <GroupAddIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: isSmallScreen ? "0.75rem" : "1rem",
                  },
                }}
              />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link
            href="/users"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.numberOfCases}
                subtitle="Users"
                progress="0.30"
                increase={`+${data.courtCasePercentage || 0}%`}
                icon={
                  <GroupAddIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: isSmallScreen ? "0.75rem" : "1rem",
                  },
                }}
              />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link
            href="/individual-clients"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.numberOfIndividualClients}
                subtitle="Individual Clients"
                progress="0.80"
                increase={`+${data.individualClientPercentage || 0}%`}
                icon={
                  <GroupAddIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: isSmallScreen ? "0.75rem" : "1rem",
                  },
                }}
              />
            </Box>
          </Link>
        </Grid>
        <Grid
          item
          md={8}
          sx={{ display: { sm: "none", md: "block", lg: "none" } }}
        />
        {/* row 2 */}
        <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Case Overview</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <Box
            sx={{ mt: 1 }}
            height="400px"
            backgroundColor={colors.primary[400]}
            borderRadius="0.5rem"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <BarChart />
            {/* <LineChart /> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Executive Summary</Typography>
            </Grid>
          </Grid>
          <MainCard sx={{ mt: 1, height: "400px" }} content={false}>
            <Box sx={{ p: 3, pb: 0 }}>
              <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>
                <ListItemButton divider>
                  <ListItemText primary="Cases Closed" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.casesClosed}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Court Cases" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfCourtCases}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Case Events" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfCaseEvents}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="New Clients" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfNewClients}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Matter Completion" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.matterCompletion}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Task Completion" />
                  <Typography sx={{ marginRight: "1rem" }}>
                    {data.taskCompletion}
                  </Typography>
                </ListItemButton>
              </List>
            </Box>
          </MainCard>
        </Grid>

        {/* row 3 */}
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Recent Tasks</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <OrderTable />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography variant="h5">Analytics</Typography>
            </Grid>
            <Grid item />
          </Grid>
          {/* <Grid container rowSpacing={2.5} columnSpacing={2.75}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Won"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Lost"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Dropped"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
          </Grid> */}
          <MainCard sx={{ mt: 2 }} content={false}>
            <Box height={"400px"}>
              <LineChart />
            </Box>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
