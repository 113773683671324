import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EmailTemplates from '../EmailTemplates';
import { tokens } from '../../../../theme';
import {useTheme } from "@mui/material";

const EmailTemplatesView = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    return (
        <Card  sx={{ bgcolor:  `${colors.primary[400]}`}}>
            <CardContent>
                <div className="col-xl-12">
                    
                    <EmailTemplates/>
                </div>
            </CardContent>
        </Card>
    );
}

export default EmailTemplatesView;