import React from 'react';
import Navbar from "./componets/Navbar";
import FeatureSection from "./componets/FeatureSection";
import Workflow from "./componets/Workflow";
import Footer from "./componets/Footer";
import Testimonials from "./componets/Testimonials";
import Hero from './componets/Hero';
import Contact from './componets/contactUs'
const LandingPage = () => {
  return (
    <>
      <Navbar />
      <Hero/>
      <div className="max-w-7xl mx-auto pt-20 px-6">
        <FeatureSection />
        <Workflow />

        <Testimonials />
        <div></div>
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;