
export const MegaModel = {
    dashboardModel: {
        firstCard: null,
        secondCard: null,
        thirdCard: null,
        fourthCard: null,
    },
    caseManagementModel: {
        caseDetails: null,
        caseDashboard: null,
        documentUploads: {
            currentDocument: null,
            loadingState: null,
        },
    },
}