import React from "react";
import Slider from "react-slick";
import {
  Container,
  Typography,
  Box,
  Avatar,
  Paper,
  useTheme,
} from "@mui/material";
import { testimonials } from "../../../components/LandingPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { tokens } from "../../../theme";

const Testimonials = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Container className="mt-20" id="testimonials" sx={{ mt: 5 }}>
      <Typography variant="h3" textAlign="center" mb={4}>
        Clients Testimonials
      </Typography>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            paddingLeft="10%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              elevation={3}
              sx={{
                padding: "30px",
                maxWidth: "800px",
                backgroundColor: colors.primary[400],
                boxShadow: `0px 4px 10px ${colors.grey[500]}`, // Use theme color for shadow
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "1.2rem",
                  lineHeight: "1.6",
                  marginBottom: "20px",
                }}
              >
                {testimonial.text}
              </Typography>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={testimonial.image}
                  alt={testimonial.user}
                  sx={{ width: "60px", height: "60px", marginRight: "20px" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "1.2rem", marginBottom: "8px" }}
                  >
                    {testimonial.user}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "1rem" }}>
                    {testimonial.company}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Testimonials;
