import { Box } from "@mui/material";

import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@emotion/react";

import CountryForm from "./Form";
import { CountriesColumns } from "../../../../data/Columns/GeographicalColumns";
import { allCountries } from "../../../../data/Axios/queries";
import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { EmployeePaymentTypes, NotificationTemplate } from "../../../../data/Queries/SetupManagement/MessageTemplateQueries";
import { EmployeePaymentTypesColumns } from "../../../../data/Columns/MessageTemplatesColumns";
import EmploymentPaymentTypeForm from "./Form";
const base_url = setupManagementUrl.uri;
const actions = {
  entity: "MessageTemplates",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function EmploymentPaymentTypes() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="EmployeePaymentTypes"
          subtitle="Manage Employee Payment Types"
          columns={EmployeePaymentTypesColumns}
          FormComponent={EmploymentPaymentTypeForm}
          query={EmployeePaymentTypes}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default EmploymentPaymentTypes;