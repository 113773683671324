export const allCourts = `
{
    allCourts {
        id
        court
        courtTypeId
        courtType
        countryId
        country
        countyId
        county
        subCountyId
        subCounty
        latitude
        longitude
        createdDate
        createdBy
        updatedBy
        updatedDate
        companyId
        company
    }
}
`;

export const allCourtTypes = `
{
    allCourtTypes {
        id
        courtType
        createdDate
        createdBy
        updatedBy
        updatedDate
        companyId
        company
    }
}`;

export const gender = `{
    allGenders {
        id
        gender
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}`;

export const identificationType = `
{
    allIdentificationTypes {
        id
        identificationType
        createdDate
        createdBy
        updatedBy
        updatedDate
        companyId
        company
    }
}
`;

export const judges = `{
    allJudges {
        judgeId
        name
        companyId
        company
    }
}`;

export const phoneCodes = `{
    allPhoneCodes {
        id
        phoneCode
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}
`;

export const titles = `{
    allTitles {
        id
        title
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}
`;