import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_COUNTRY, UPDATE_COUNTRY, ALL_COUNTRIES } from "../../../../data/SetupManagementData";

const CountryForm = (props) => {
  const {
    loading: countryLoading,
    error: countryError,
    data: countryData,
  } = useQuery(ALL_COUNTRIES);

  const [createCountry] = useMutation(CREATE_COUNTRY, {
    onCompleted: () => {
      props.onClose()
      props.refetch()
      swal("Success!", "Country has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create country, try again later", "error");
    },
  });

  const [updateCountry] = useMutation(UPDATE_COUNTRY, {
    onCompleted: () => {
      props.onClose()
      props.refetch()
      swal("Success!", "Country has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update country, try again later", "error");
    },
  });

  const initialValues = {
    country: props.countryData ? props.countryData.country : "",
    countryInitials: props.countryData ? props.countryData.countryInitials : "",
  };

  const validationSchema = yup.object().shape({
    country: yup.string().required("Country is required"),
    countryInitials: yup.string().required("Country Initials is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { country, countryInitials } = values;

      if (props.isEditing) {
        props.onClose();
        await updateCountry({
          variables: {
            id: props.countryData.id,
            country,
            countryInitials,
          },
        });
        console.log("Country updated successfully!");
      } else {
        await createCountry({
          variables: {
            country,
            countryInitials,
          },
        });
        console.log("Country created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Country" : "Create Country"}
        subtitle={
          props.isEditing ? "Edit an Existing Country" : "Create a New Country"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                name="country"
                error={touched.country && !!errors.country}
                helperText={touched.country && errors.country}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country Initials"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.countryInitials}
                name="countryInitials"
                error={touched.countryInitials && !!errors.countryInitials}
                helperText={touched.countryInitials && errors.countryInitials}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CountryForm;
