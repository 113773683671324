import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CasesForm from "./Form";
import {
  FactColumns,
  TeamAssignmentsColumns,
} from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import TeamAssignmentForm from "./Form";
import { PulseLoader } from "react-spinners";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Cases",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: false,
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  // ViewCase: {
  //   key: "AssignTask",
  //   button_name: "View Details",
  //   Show_Button: false,
  // },
};
function CaseFacts() {
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      const returnFields = [
        "id",
        "caseId",
        "factDescription",
        "factDate",
        "company",
      ];
      const inputFields = {
        caseId: caseId,
      };
      const Facts = await fetchDataEngine(
        "allFactsByColumnValues",
        "factModel",
        inputFields,
        returnFields,
        base_url
      );
      setData(Facts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box m="2px">
      <>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <PulseLoader size={15} color={"#3f51b5"} />
          </Box>
        ) : (
          <DynamicTable
            title="Facts"
            subtitle="View all Facts related to a case, create or edit  a fact"
            columns={FactColumns}
            FormComponent={TeamAssignmentForm}
            DataFromGetBy={data}
            actions={actions}
            fetchData={() => fetchData()}
          />
        )}
      </>
    </Box>
  );
}

export default CaseFacts;
