import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme, Select, MenuItem } from "@mui/material";
import { tokens } from "../../../theme";
import EmploymentPaymentView from "./ComponentMessages/EmploymentView";
import SmsTemplateView from "./ComponentMessages/SmsTemplateView";
import EmailTemplatesView from "./ComponentMessages/EmailTemplate";
import AppNotifTemplateView from "./ComponentMessages/AppNotifTemplateView";
import { useMediaQuery } from "@mui/material";

const MessageTemplates = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    handleChange(event, event.target.value);
  };
  const getTabStyle = (isSelected) => ({
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
    m: 0.3,
    color: isSelected
      ? theme.palette.mode === "dark"
        ? colors.greenAccent[400]
        : colors.primary[100]
      : "inherit",
    bgcolor: isSelected
      ? theme.palette.mode === "light"
        ? colors.primary[200]
        : colors.greenAccent[400]
      : "inherit",
    "&.Mui-selected": {
      color:
        theme.palette.mode === "dark"
          ? colors.primary[400]
          : colors.primary[900],
    },
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const tabs = [
    {label: "Employment Payment", component: <EmploymentPaymentView />},
    {label: "SMS", component: <SmsTemplateView />},
    {label: "Email", component: <EmailTemplatesView />},
    {label: "App Notification", component: <AppNotifTemplateView />}
  ];

  return (
    <div className="container-fluid">
      {/* <Box sx={{ p: 1, m: 2, bgcolor: `${colors.primary[400]}` }}>
        <Card sx={{ borderRadius: "0.9em", height: "100%" }}> */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns:
            isSmallScreen || isMediumScreen ? "1fr" : "auto 1fr",
          height: "max-content",
          bgcolor: `${colors.primary[400]}`,
          // p: 2,
          m: 2,
          width: "100%",
        }}
      >
        {/* Tabs or drop down */}
        {isSmallScreen || isMediumScreen ? (
          <Select
            value={value}
            onChange={handleSelectChange}
            fullWidth
            displayEmpty
            inputProps={{"aria-label": "without label"}}
            sx={{ bgcolor: `${colors.primary[400]}`, width: "300px" }}
          >
            {tabs.map((tab, index) => (
              <MenuItem
                key={index}
                value={index}
                sx={{ bgcolor: `${colors.primary[400]}` }}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Select>
        ): (
          <Tabs
          orientation={
            isSmallScreen || isMediumScreen ? "horizontal" : "vertical"
          }
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Message Templates"
          sx={{
            borderRight: isSmallScreen || isMediumScreen ? 0 : 2,
            borderBottom: isSmallScreen || isMediumScreen ? 2 : 0,
            bgcolor: `${colors.primary[400]}`,
          }}
        >
          <Tab
            label="Employment Payment"
            sx={{
              ...getTabStyle(value === 0),
              minWidth: isSmallScreen || isMediumScreen ? "30px" : "90px",
              fontSize: isSmallScreen || isMediumScreen ? "10px" : "14px",
            }}
            {...a11yProps(0)}
            className={
              value === 0 ? "custom-tab-active custom-tab" : "custom-tab"
            }
          />
          <Tab
            label="Sms"
            sx={{
              ...getTabStyle(value === 1),
              minWidth: isSmallScreen || isMediumScreen ? "30px" : "90px",
              fontSize: isSmallScreen || isMediumScreen ? "10px" : "14px",
            }}
            {...a11yProps(1)}
            className={
              value === 1 ? "custom-tab-active custom-tab" : "custom-tab"
            }
          />
          <Tab
            label="Email"
            sx={{
              ...getTabStyle(value === 2),
              minWidth: isSmallScreen || isMediumScreen ? "30px" : "90px",
              fontSize: isSmallScreen || isMediumScreen ? "10px" : "14px",
            }}
            {...a11yProps(2)}
            className={
              value === 2 ? "custom-tab-active custom-tab" : "custom-tab"
            }
          />
          <Tab
            label="App Notification"
            sx={{
              ...getTabStyle(value === 3),
              minWidth: isSmallScreen ? "30px" : "90px",
              fontSize: isSmallScreen ? "10px" : "14px",
            }}
            {...a11yProps(3)}
            className={
              value === 3 ? "custom-tab-active custom-tab" : "custom-tab"
            }
          />
          {/* <Tab
                label="Branch"
                {...a11yProps(4)}
                className={
                  value === 4? "custom-tab-active custom-tab" : "custom-tab"
                }
              /> */}
          {/*  <Tab
              label="Farm Structure"
              {...a11yProps(5)}
              className={
                value === 5 ? "custom-tab-active custom-tab" : "custom-tab"
              }
            />
            <Tab
            label="Farm power Source"
            {...a11yProps(6)}
            className={
              value === 6 ? "custom-tab-active custom-tab" : "custom-tab"
            }
            
          />
             <Tab
            label="Farm Ownership"
            {...a11yProps(7)}
            className={
              value === 7 ? "custom-tab-active custom-tab" : "custom-tab"
            }/>
             <Tab
            label="Farm Asset Type"
            {...a11yProps(8)}
            className={
              value === 8 ? "custom-tab-active custom-tab" : "custom-tab"
            }/> */}
          {/* <Tab
            label="All Farm Assets"
            {...a11yProps(9)}
            className={
              value === 9 ? "custom-tab-active custom-tab" : "custom-tab"
            }/> */}
        </Tabs>
        )}
        

        {/* Tab Content */}
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.component}
          </TabPanel>
        ))}

        {/* <TabPanel value={value} index={0} className="w-100">
          <EmploymentPaymentView />
        </TabPanel>
        <TabPanel value={value} index={1} className="w-100">
          <SmsTemplateView />
        </TabPanel>
        <TabPanel value={value} index={2} className="w-100">
          <EmailTemplatesView />
        </TabPanel>
        <TabPanel value={value} index={3} className="w-100">
          <AppNotifTemplateView />
        </TabPanel> */}
      </Box>
      {/* </Card>
      </Box> */}
    </div>
  );
};

export default MessageTemplates;
