import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl, setupManagementUrl, smsservice } from "../../../config";

import { SmsByCompanyColumns } from "../../../data/DynamicTable/AdhocColumns";
import { allAdhocSmS } from "../../../data/Axios/queries";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { TextareaAutosize } from "@mui/material";

const sms_base_url = smsservice.uri;
const actions = {
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: false,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: false,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function AdhocSms() {
      const theme = useTheme();
      const colors = tokens(theme.palette.mode);
      const MultilineTextCell = ({ value }) => {
        return (
          <TextareaAutosize
            aria-label="body content"
            minRows={3}
            style={{
              color: "white",
              width: "100%",
              resize: "none",
              border: "none",
              padding: "8px",
              backgroundColor: `${colors.primary[400]}`,
            }}
            value={value}
            readOnly
          />
        );
    };
      const columns = [
        ...SmsByCompanyColumns,

        {
          field: "body",
          headerName: "Body",
          flex: 3,
          renderCell: (params) => <MultilineTextCell value={params.value} />,
        },
     
        { field: "status", headerName: "Status", flex: 1 },
        { field: "sentDateTime", headerName: "Sent Date Time", flex: 1 },
      ];
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Adhoc SmS"
          subtitle="Manage adhoc SmS"
          columns={columns}
          //FormComponent={AddOnForm}
          query={allAdhocSmS}
          base_url={sms_base_url}
          actions={actions}
          getRowId={(row) => row.messageId}
        />
      </>
    </Box>
  );
}

export default AdhocSms;
