import { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  generateAndExecuteMutation,
  generateMutation,
  graphqlMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import {
  caseManagementUrl,
  clientManagementUrl,
  setupManagementUrl,
} from "../../../config";
import {
  allCorporateClients,
  allIndividualClients,
  caseCategory,
  allGenders,
} from "../../../data/Axios/queries";
import { fetchDataEngine } from "../../../data/Axios/DynamicService";
import { useState } from "react";
import Header from "../../../components/Header";

const base_url = clientManagementUrl.uri;

const IndividualClientsForm = (props) => {
  const [genderData, setGenderData] = useState([]);
  const [loading, setLoading] = useState(true);

  const initialValues = {
    id: props.data ? props.data.id : null,
    firstName: props.data ? props.data.firstName : null,
    lastName: props.data ? props.data.lastName : null,
    dateOfBirth: props.data ? props.data.dateOfBirth : null,
    genderId: props.data ? props.data.genderId : null,
    nationality: props.data ? props.data.nationality : null,
    identificationType: props.data ? props.data.identificationType : null,
    identificationNumber: props.data ? props.data.identificationNumber : null,
    contactNumber: props.data ? props.data.contactNumber : null,
    email: props.data ? props.data.email : null,
    address: props.data ? props.data.address : null,
    occupation: props.data ? props.data.occupation : null,
    employer: props.data ? props.data.employer : null,
    isActive: props.data ? props.data.isActive : 1,
    emergencyContactName: props.data ? props.data.emergencyContactName : null,
    emergencyContactNumber: props.data
      ? props.data.emergencyContactNumber
      : null,
    relationshipWithEmergencyContact: props.data
      ? props.data.relationshipWithEmergencyContact
      : null,
    notes: props.data ? props.data.notes : null,
  };

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const genderResponse = await graphqlQuery(
          allGenders,
          setupManagementUrl.uri
        );
        if (genderResponse) {
          setGenderData(genderResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    contactNumber: yup.string().required("Contact Number is required"),
    email: yup.string(),
    genderId: yup.string().required("Gender is required"),
  });

  const genderOptions = genderData
    ? genderData.map((gender) => ({
        parent_key: gender.id,
        value: gender.id,
        label: gender.gender,
      }))
    : [];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        firstName,
        lastName,
        dateOfBirth,

        nationality,
        identificationType,
        identificationNumber,
        genderId,
      } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            firstName,
            lastName,
            dateOfBirth,

            nationality,
            identificationType,
            identificationNumber,
            genderId,
          }
        : {
            firstName,
            lastName,
            dateOfBirth,

            nationality,
            identificationType,
            identificationNumber,
            genderId,

            createdBy: userId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateIndividualClient" : "createIndividualClient",
        mutationData,
        props.isEditing ? "updatedIndividualClient" : "newIndividualClient",
        base_url
      );

      if (response) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Client ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        }).then(() => {
          props.onAction(); // Close the modal after the success message
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Client" : "Create Client"}
        subtitle={
          props.isEditing ? "Edit an Existing Client" : "Create a New Client"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date of Birth"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dateOfBirth}
                name="dateOfBirth"
                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contactNumber}
                name="contactNumber"
                error={touched.contactNumber && !!errors.contactNumber}
                helperText={touched.contactNumber && errors.contactNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />

              <Select
                fullWidth
                variant="filled"
                name="genderId"
                value={values.genderId}
                onBlur={handleBlur}
                label="Gender"
                onChange={handleChange}
                displayEmpty
                error={touched.genderId && !!errors.genderId}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value="" disabled>
                  Select Gender
                </MenuItem>
                {genderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default IndividualClientsForm;
