export const allLeadSources = `
    {
  allLeadSources {
    id
    leadSource
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}
`;

export const allLeadStatus =`
{
  allLeadStatuses {
    id
    leadStatus
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}`;