import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import {
  CREATE_USER,
  UPDATE_USER,
  ACTIVATE_USER,
  DEACTIVATE_USER,
} from "../../data/userData";
import {
  fetchDataEngine,
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../data/Axios/DynamicService";
import {
  allCompanies,
  allRegions,
  getrolesquery,
  allUsersQuery,
  allGenders,
} from "../../data/Axios/queries";
import {
  companyManagementUrl,
  roleManagemenUrl,
  setupManagementUrl,
  userManagementUrl,
} from "../../config";
import DynamicForm from "../../data/Axios/DynamicForm";
import { usersField } from "../../data/DynamicTable/usersDynamicForms";
import Swal from "sweetalert2";

const base_url = roleManagemenUrl.uri;

const UsersForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const [roleData, setRoleData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [showBranches, setShowBranches] = useState(false);
  const [genderData, setGenderData] = useState([]);

  const initialValues = {
    firstName: props.userData ? props.userData.firstName : "",
    lastName: props.userData ? props.userData.lastName : "",
    email: props.userData ? props.userData.email : "",
    phone: props.userData ? props.userData.phone : "",
    username: props.userData ? props.userData.username : "",
    idno: props.userData ? props.userData.idno : "",
    roleId: props.userData ? props.userData.roleId : "",
    roleName: props.userData ? props.userData.roleName : "",
    companyId: props.userData ? props.userData.companyId : "",
    genderId: props.userData ? props.userData.genderId : "",
    regionId: props.userData ? props.userData.regionId : "",
    branchId: props.userData ? props.userData.branchId : "",
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const roleResponse = await graphqlQuery(getrolesquery, base_url);
        if (roleResponse) {
          setRoleData(roleResponse || []);
        }

        const regionResponse = await graphqlQuery(
          allRegions,
          companyManagementUrl.uri
        );
        if (regionResponse) {
          setRegionData(regionResponse || []);
        }

        // Fetch branch data if userData has regionId
        if (props.userData && props.userData.regionId) {
          const branchResponse = await fetchBranches(props.userData.regionId);
          setBranchData(branchResponse || []);
        }

        // Determine visibility of regions and branches
        if (props.userData && props.userData.roleId) {
          handleRoleChange(props.userData.roleId);
        }
        const genderResponse = await graphqlQuery(
          allGenders,
          setupManagementUrl.uri
        );
        if (genderResponse) {
          setGenderData(genderResponse);
          console.log(genderResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, [props.userData]);

  const fetchBranches = async (regionId) => {
    try {
      const inputFields = { regionId };
      const returnFields = ["id", "branchName"];
      const response = await fetchDataEngine(
        "allBranchesByColumnValues",
        "branchModel",
        inputFields,
        returnFields,
        companyManagementUrl.uri
      );
      return response.map((branch) => ({
        value: branch.id,
        label: branch.branchName,
      }));
    } catch (error) {
      console.error("Error fetching branches:", error);
      return [];
    }
  };

  const handleFieldChange = async (field, value) => {
    if (field.name === "roleId") {
      handleRoleChange(value);
    }
    if (field.name === "regionId") {
      const branchOptions = await fetchBranches(value);
      console.log("Branch Options:", branchOptions); // Log branch options
      setBranchData(branchOptions || []); // Ensure an array is set
    }
  };

  const handleRoleChange = (roleId) => {
    const selectedRole = roleData.find((role) => role.id === parseInt(roleId));
    if (selectedRole) {
      const { roleGroupId } = selectedRole;
      if (roleGroupId === 1 || roleGroupId === 2) {
        setShowRegions(true);
        setShowBranches(true);
      } else if (roleGroupId === 3) {
        setShowRegions(false);
        setShowBranches(true);
      } else if (roleGroupId === 4) {
        setShowRegions(false);
        setShowBranches(false);
      }
    }
  };
  // const companyOptions = companyData
  //   ? companyData.map((company) => ({
  //       value: company.id,
  //       label: company.companyName,
  //     }))
  //   : [];

  const roleOptions = roleData
    ? roleData.map((role) => ({
        parent_key: role.id,
        value: role.id,
        label: role.role,
      }))
    : [];
  const regionOptions = regionData
    ? regionData.map((role) => ({
        parent_key: role.id,
        value: role.id,
        label: role.regionName,
      }))
    : [];

  const genderOptions = genderData
    ? genderData.map((user) => ({
        parent_key: user.id,
        value: user.id,
        label: user.gender,
      }))
    : [];
  const branchOptionsOptions = branchData
    ? branchData.map((user) => ({
        value: user.id,
        label: user.branchName,
      }))
    : [];
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        username,
        idno,
        roleId,
        gender,
        companyId,
        regionId,
        branchId,
        genderId,
      } = values;

      const mutationData = {
        firstName,
        lastName,
        email,
        phone,
        username,
        idno,
        genderId,
        branchId: parseInt(showBranches ? branchId : null),
        regionId: parseInt(showRegions ? regionId : null),
        roleId: parseInt(roleId),
        companyId: parseInt(companyId),
        createdBy: userId,
      };

      if (props.isEditing) {
        mutationData.id = props.userData.id;
      }

      const response = await generateAndExecuteMutation(
        props.isEditing ? "editUser" : "createUser",
        mutationData,
        "user",
        userManagementUrl.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.refetch();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Record ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const fields = [
    ...usersField,
    {
      name: "genderId",
      label: "Gender",
      type: "select",
      options: genderOptions,
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "roleId",
      label: "Role",
      type: "select",
      options: roleData
        ? roleData.map((role) => ({
            value: role.id,
            label: role.role,
          }))
        : [],
      gridColumn: "span 2",
      required: true,
      parent: "main",
    },
    ...(showRegions
      ? [
          {
            name: "regionId",
            label: "Region",
            type: "select",
            options: regionData
              ? regionData.map((region) => ({
                  value: region.id,
                  label: region.regionName,
                }))
              : [],
            gridColumn: "span 2",
            parent: "main",
          },
        ]
      : []),
    ...(showBranches
      ? [
          {
            name: "branchId",
            label: "Branch",
            type: "select",
            options: branchData,
            gridColumn: "span 2",
            parent: "dependent",
            dependentOn: "regionId",
          },
        ]
      : []),
  ];

  return (
    <div>
      <DynamicForm
        initialValues={initialValues}
        fields={fields}
        onFieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        title={props.isEditing ? "Edit User" : "Create User"}
        subtitle={
          props.isEditing ? "Edit an Existing User" : "Create a New User"
        }
      />
    </div>
  );
};

export default UsersForm;
