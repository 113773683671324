
import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { allEvents } from "../../../../data/Queries/SetupManagement/OtherSetupsQueries";
import {EventTypeColumn } from "../../../../data/Columns/otherSetupColumns";
import EventForm from "./Form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Event",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function Events() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Event Types"
          subtitle="Manage Event Types"
          columns={EventTypeColumn}
          FormComponent={EventForm}
          query={allEvents}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default Events;