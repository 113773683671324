import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { graphqlQuery } from "../../data/Axios/DynamicService";
import { recentCaseTasks } from "../../data/Axios/queries";
import { caseManagementUrl } from "../../config";
import { fetchrecenttasks } from "../../data/BarData";

// Function to create data structure for the rows
function createData(id, caseTitle, description, statusFlag, companyId) {
  return { id, caseTitle, description, statusFlag, companyId };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    align: "left",
    disablePadding: false,
    label: "case Id",
  },
  {
    id: "caseTitle",
    align: "left",
    disablePadding: false,
    label: "Case Title",
  },
  {
    id: "description",
    align: "left",
    disablePadding: false,
    label: "Description",
  },
  {
    id: "statusFlag",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "companyId",
  //   align: "right",
  //   disablePadding: false,
  //   label: "Total Amount",
  // },
];

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function OrderStatus({ status }) {
  let color;
  let title;

  switch (status) {
    case 0 || null:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>{title}</Typography>
    </Stack>
  );
}

// Main OrderTable component
export default function OrderTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const order = "asc";
  const orderBy = "id";

  useEffect(() => {
    let isMounted = true;
    async function fetchAllData() {
      try {
        if (isMounted) {
          const recentTasks = await fetchrecenttasks();
          if (recentTasks) {
            // Transform fetched data into the required format
            const transformedData = recentTasks.data.recentCaseTasks.map(
              (task) =>
                createData(
                  task.id,
                  task.caseTitle,
                  task.description,
                  task.statusFlag,
                  task.companyId
                )
            );
            setData(transformedData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
          maxHeight: "400px",
          padding: "1rem",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table aria-labelledby="tableTitle">
          <OrderTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      <Link color="secondary">{row.id}</Link>
                    </TableCell>
                    <TableCell align="left">{row.caseTitle}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell>
                      <OrderStatus status={row.statusFlag} />
                    </TableCell>
                    {/* <TableCell align="right">
                    <NumericFormat
                      value={row.companyId}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </TableCell> */}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.any,
  orderBy: PropTypes.string,
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};
