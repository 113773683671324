import axios from "axios";
import { caseManagementUrl, deleteDocumentService } from "../../config";
import { generateAndExecuteMutation } from "../Axios/DynamicService";


const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
  };
};

export async function markTaskDone(id) {
    try {  
        const mutationData = {
            id
        };
    const response = await generateAndExecuteMutation(
        "markTaskDone",
        mutationData,
      "task",
      caseManagementUrl.uri
    );
        if (Object.values(response)[0] != null) { }
        return true;
    }
    catch (error) {
        return false
}
    

}

export async function deleteDocument(fileName, documentId) {
  const baseurldelete = deleteDocumentService.uri;
     const headers = getAuthHeaders();
  const postData = {
    fileName,
    documentId,
  };

  try {
    const response = await axios.post(baseurldelete, postData, { headers });
    return response;
  } catch (error) {
    return error.code;
  }
}